import React, { Component } from "react";
import Menu from "Partials/Menu";
import "./UserMessageBox.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import General from "Helpers/General";
import Category from "Models/Category";
import MyProfile from "Models/MyProfile";
import ScheduleProductModel from "Models/ScheduleProduct";
import axios from "axios";
import User from "Models/User";
import { Link, Redirect } from "react-router-dom";
import Moment from "react-moment";
import moment from "moment";
import Storage from "Helpers/Storage";
import formData from "form-data";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import ApiEndPoints from "Helpers/ApiEndPoints";
import Lodash from "lodash";
import { usePaystackPayment } from "react-paystack";
import { ChannelList } from "./ChannelList";
import { MessagesPanel } from "./MessagesPanel";
import socketClient from "socket.io-client";

const SERVER = process.env.REACT_APP_SERVER;
class UserMessageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Value: props.slugValue,
      MyProfileState: MyProfile.dbSchema(),
      id: "",
      firstName: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["firstName"]
        : "",
      lastName: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["lastName"]
        : "",
      email: "",
      mobileNumber: "",
      city: "",
      state: "",
      address: "",
      companyName: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["companyName"]
        : "",
      avgRating: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["avgRating"]
        : 0,
      aboutCompany: "",
      startWorkingTime: "",
      endWorkingTime: "",
      changePassword: "",
      newPassword: "",
      confirmPassword: "",
      messaging: false,
      reviews: false,
      agriculture: false,
      sale: false,
      jobs: false,
      services: false,
      motors: false,
      property: false,
      facebookProvider: "",
      twitterProvider: "",
      memberSince: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["date"]
        : "",
      allReviews: localStorage.getItem("MyReviews")
        ? JSON.parse(localStorage.getItem("MyReviews"))
        : "",
      products: localStorage.getItem("MyProducts")
        ? JSON.parse(localStorage.getItem("MyProducts"))
        : "",
      wishlists: "",
      totalCount: localStorage.getItem("MyProducts")
        ? JSON.parse(localStorage.getItem("MyProducts")).length
        : "",
      activeProductsCount: 0,
      pendingProductsCount: 0,
      searchText: "",
      profilePicture: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["profilePicture"]
        : "",
      deactivateAccountReasons: [],
      deactivateAccountReasonSelected: "",
      workingDaysList: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      selectedWorkingDays: [],
      packages: "",
      current_package: "",
      package_details: {},
      pack: "",
      current_index: 0,
      terminationOfUserAccount: true,
      channels: null,
      socket: null,
      channel: null,
      SelectedName: "",
      Datachat: [],
      SelectedTime: "",
      refresh: false,
      onlineData: "",
      selectedId: "",
      userDetails: "",
      allCategories: localStorage.getItem("MenuCategories")
        ? JSON.parse(localStorage.getItem("MenuCategories"))
        : [],
      selectedCategories: [],
      selectedCategoryProducts: [],
      upcomingLiveSchedule: [],
      completedLiveSchedule: [],
      scheduleDate: "",
      scheduleTime: "",
      scheduleDuration: "",
      scheduleLength: "",
      scheduleSelectedProducts: [],
      scheduleSelectedProductsId: [],
      showProductDropdown: false,
      editLiveSchedule: false,
      showEditProductDropdown: false,
      selectedEditLiveSchedule: "",
      editScheduleSelectedProducts: [],
      editScheduleSelectedProductsId: [],
      editSelectedCategories: [],
      safetyTips: [],
      editSelectedCategoryProducts: [],
      filterValue: "",
      msgFilter:''
    };
  }

  configureSocket = () => {
    const senderNamew = localStorage.getItem("AuthIdentity");
    const base64Url = senderNamew.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    const UserId = JSON.parse(window.atob(base64));
    var socket = socketClient(SERVER, {
      query: "user_id=" + UserId.response._id,
    });

    socket.emit("login", { userId: UserId.response._id });
    socket.on("channel", (channel) => {
      let channels = this.state.channels;
      channels.forEach((c) => {
        if (c.userId === channel.id) {
        }
      });
      this.setState({ channels });
    });

    socket.on("onlinestatus", (data) => {
      this.loadChannels(data);
      this.setState({ onlineData: data });
    });
    socket.on("message", (message) => {
      this.getCheckListHandle(UserId.response._id, this.state.selectedId);
      let channels = this.state.Datachat;
      if (
        (message.toUserId === this.state.selectedId &&
          message.fromUserId === UserId.response._id) ||
        (message.fromUserId === this.state.selectedId &&
          message.toUserId === UserId.response._id)
      ) {
        channels.push(message);
        this.setState({ refresh: !this.state.refresh });
      } else {
        this.setState({ Datachat: [...channels] });
      }
      this.setState({ Datachat: [...channels] });
      this.setState({ refresh: !this.state.refresh });
    });
    this.socket = socket;
  };

  loadChannels = async (status) => {
    const senderNamew = localStorage.getItem("AuthIdentity");
    const base64Url = senderNamew.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    const UserId = JSON.parse(window.atob(base64));
    fetch(
      process.env.REACT_APP_SERVER + `/getChannels/${UserId.response._id}`
    ).then(async (response) => {
      let data = await response.json();
      if (data.channels && data.channels.length) {
        this.setState({ channels: data.channels });
      }
    });
  };

  handleFilter = (e) => {
    this.setState({ filterValue: e.target.value });
  };
  handleMessage = (e) =>{
    this.setState({ msgFilter: e.target.value });
    this.searchFilter(e.target.value)
  }

  searchFilter = (data) => {
    const senderNamew = localStorage.getItem("AuthIdentity");
    const base64Url = senderNamew.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    const UserId = JSON.parse(window.atob(base64));
    fetch(process.env.REACT_APP_SERVER+`/filterChatBox`, {
      method: "POST",
      body: JSON.stringify({
        firstName: this.state.filterValue,
        isRead: data,
        id: UserId.response._id,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }).then(async (response) => {
      if (response && response !== undefined) {
        let data = await response.json();
        this.setState({ channels: data.data });
      }
    }).catch(err => {console.log(err)});
  };

  getCheckListHandle(fromUserId, toUserId) {
    fetch(process.env.REACT_APP_SERVER + `/getChecklist`, {
      method: "POST",
      body: JSON.stringify({
        fromUserId: fromUserId,
        toUserId: toUserId,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }).then(async (response) => {
      if (response && response !== undefined) {
        let data = await response.json();
        for(let i=0;i<=data.length;i++){
          if(data[i]){
            fetch(process.env.REACT_APP_SERVER + `/readStatus`, {
              method: "POST",
              body: JSON.stringify({
                fromUserId:data[i].fromUserId,
                toUserId:data[i].toUserId,
                statusRead:true
              }),
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
              },
            })
          }

        }
        console.log(">>>>>>>>Data",data);
        this.setState({ Datachat: data });
      }
    }).catch(err => {console.log(err)});
  }

  handleSelectedProfile = (toUserId) => {
    fetch(process.env.REACT_APP_SERVER + `/getSelectedUser`, {
      method: "POST",
      body: JSON.stringify({
        id: toUserId,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }).then(async (response) => {
      if (response && response !== undefined) {
        let data = await response.json();
        if (data && data.length) {
          this.setState({ userDetails: data });
        }
      }
    }).catch(err => {console.log(err)});
  };
  safetyTipsHandle() {
    fetch(process.env.REACT_APP_SERVER + `/getSafetyTips`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }).then(async (response) => {
      if (response && response !== undefined) {
        let data = await response.json();
        this.setState({ safetyTips: data[0].content });
      }
    }).catch(err => {console.log(err)});
  }

  handleChannelSelect = (id) => {
    document.body.classList.add('overflow-none');
    document.body.classList.add('active_chat');

    const senderNamew = localStorage.getItem("AuthIdentity");
    const base64Url = senderNamew.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    const UserId = JSON.parse(window.atob(base64));
    let channel = this.state.channels.find((c) => {
      return c.userId === id;
    });
    this.safetyTipsHandle();
    this.getCheckListHandle(UserId.response._id, id);
    this.handleSelectedProfile(id);

    this.setState({ SelectedName: channel.firstName });
    this.setState({ SelectedTime: channel.lastLogin });
    this.setState({ selectedId: channel.userId });
    this.setClassHide();
    this.setState({ channel });
    this.socket.emit("channel-join", id);
  };

  closeMessageBox = (e) => {
    e.preventDefault();
    document.body.classList.remove('overflow-none');
    document.body.classList.remove('active_chat');
    this.setClassActive()
  }


  setClassActive = () => {
    const currentState = this.state.check;
    this.setState({ check: !currentState });
  };

  setClassHide = () => {
    const currentState = this.state.msgCheck;
    this.setState({ msgCheck: !currentState });
  };

  handleSendMessage = (channel_id, message, attachment) => {

    let channel = this.state.channels.find((c) => {
      return c.userId === channel_id;
    });
    const senderNamew = localStorage.getItem("AuthIdentity");
    const base64Url = senderNamew.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    const UserId = JSON.parse(window.atob(base64));
    // this.getCheckListHandle(UserId.response._id, channel_id);
    this.socket.emit("send-message", {
      fromUserId: UserId.response._id,
      message,
      attachment,
      senderText: UserId.response._id !== channel_id ? message : "",
      fromName: UserId.response.firstName,
      toUserId: channel_id,
      toName: channel.firstName,
      receiverText: UserId.response._id === channel_id ? message : "",
      id: Date.now(),
    });
  };

  componentDidMount = () => {
    this.loadChannels();
    this.configureSocket();
    MyProfile.getApi(
      "APIS_GET_ACCOUNT_DEACTIVATE_REASON",
      async (response) => {
        this.setState({ deactivateAccountReasons: response });
      },
      false
    );
  };

  render() {
    return (
      <React.Fragment>
        <section className="video-chat-header">
          <div className="container">
            <a href="#" className="video-chat-btn">
              <span>
                <img src="/images/video-chat-icon.png" alt="icon" />
              </span>{" "}
              Open Video Chat
            </a>
            <a href="#" className="number-reveal-btn ml-2">
              <img src="/images/phone-icon.png" alt="icon" />{" "}
              <span>Reveal Phone Number</span>
            </a>
          </div>
        </section>
        <section className="repeat-section product-detail-main-bx bg-light-dark page_6tabmain_top pb-5">
          <div className="container">
            <div className="product-all-detail-tab">
              <div className="tab-content">
                <div className="tab-pane active" id="subtab03" role="tabpanel">
                  <div className="subtab_prodlist_NEW_forpadd">
                    <section className="product-header bg-light-dark">
                      <div className="container">
                        <div className="row align-items-center">
                          <div className="col-12">
                            <div className="breadcrumb-bx">
                              <div className="breadcrumb-bx-inner">
                                {/* <a href="/my-profile" className="btnx btn-primaryx"> */}
                                <Link
                                  to={`/my-profile`}
                                  className="btnx btn-primaryx"
                                >
                                  Back
                                </Link>
                                {/* </a> */}
                                <ol className="breadcrumb mb-0 ml-3">
                                  <li className="breadcrumb-item">
                                    <a href="#">Home</a>
                                  </li>
                                  <li
                                    className="breadcrumb-item active"
                                    aria-current="page"
                                  >
                                    Chat
                                  </li>
                                </ol>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="chatmainS pagemain_chat02 bg-light-dark">
                      <div className="container">
                        <div className="row">
                          <div className="col-sm-9 chatdeskfull_rig">
                            <div className="transparent-layer" />
                            <div className="mesagebt_mobS">
                              <a href="#" className="btnunique_mess">
                                <i className="fas fa-long-arrow-alt-left" />{" "}
                                &nbsp;Messages
                              </a>
                            </div>
                            <div className="chatandlist">
                              <div className="rightchat_list">
                                <div className="chatTeam_head">
                                  <div className="listleftH">
                                    <div className="down_filtdrop">
                                      <select className=''   onChange={this.handleMessage}>
                                        <option value='false'>Unread</option>
                                        <option value='true'>Read</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="listrightH">
                                    <a
                                      data-toggle="collapse"
                                      data-target="#demo"
                                      className="icons_messager"
                                    >
                                      <i
                                        className="fas fa-search"
                                        onClick={this.searchFilter}
                                      />
                                    </a>
                                    <div
                                      className="downserch collapse"
                                      id="demo"
                                    >
                                      <input
                                        type="text"
                                        onChange={this.handleFilter}
                                        className
                                        placeholder="Search..."
                                      />
                                    </div>
                                  </div>
                                </div>
                                {/* <div
                                      className="chat_teambx mCustomScrollbar"
                                      data-mcs-theme="dark"
                                    > */}
                                      <ChannelList
                                        channels={this.state.channels}
                                        onSelectChannel={
                                          this.handleChannelSelect
                                        }
                                        DataValue={this.state.Datachat}
                                      />
                                    {/* </div> */}
                                  </div>
                                  {/* End rightchat_list */}
                                  <div
                                    className={
                                      this.state.msgCheck
                                        ? "chatboxL"
                                        : "chatboxL d-none"
                                    }
                                  >
                                    <div className="chatbox_head">
                                      <div className="chatleftH_title">
                                        <h6>
                                          <Link
                                            className="backonlymes_chatmob pl-0"
                                            onClick={this.closeMessageBox}
                                          >
                                            <i className="fas fa-arrow-left"></i>
                                          </Link>

                                          <img
                                            src="/images/messanger.png"
                                            alt="Icon"
                                          />   
                              
                                         
                                          <span className="catbbss_titl">
                                            {this.state.SelectedName}
                                          </span>
                                          <p>
                                            {this.state.SelectedTime
                                              ? "Last seen " + moment(
                                                  this.state.SelectedTime
                                                ).fromNow() + " | " + moment(this.state.SelectedTime).format("MMM Do, h:mm:ss a")
                                              // ? moment(
                                              //     this.state.SelectedTime
                                              //   ).format("dddd, MMMM Do YYYY, h:mm:ss a")
                                              : ""}
                                          </p>
                                        </h6>
                                      </div>
                                      <div className="chatclose">
                                        <a href="#">
                                          <img
                                            src="images/videoicon-white.png"
                                            alt="Icon"
                                          />
                                        </a>{" "}
                                        &nbsp;
                                        <a href="#">
                                          <img
                                            src="images/phone-icon-white.png"
                                            alt="Icon"
                                          />
                                        </a>
                                        <a
                                          href="#"
                                          id="chatmenu_showP"
                                          className="backonlymes_chatmob"
                                        >
                                          <i className="fas fa-ellipsis-v" />
                                        </a>
                                      </div>
                                    </div>
                                    {/* <div
                                      className="chatingbx mCustomScrollbar"
                                      data-mcs-theme="dark"
                                    > */}
                                {/* {this.state.Datachat?<p className="start_conversation">
                                        Once you start a new conversation,
                                        you’ll see it listed here.
                                      </p>:''} */}
                                {/* </div> */}
                                <MessagesPanel
                                  onSendMessage={this.handleSendMessage}
                                  channel={this.state.channel}
                                  DataValue={this.state.Datachat}
                                />
                              </div>
                              {/* End chatboxL */}
                            </div>
                          </div>
                          <div className="col-sm-3 prdetail_rightchat">
                            <div className="mesagebt_mobS">
                              <a href="#" className="btnunique_mess">
                                <i className="fas fa-long-arrow-alt-left" />{" "}
                                &nbsp;Chat Details
                              </a>
                            </div>
                            {this.state.userDetails &&
                            this.state.userDetails.length ? (
                              <div className="product-detail-owner-bx">
                                <div className="detail-owner-header chatnewmotor_second">
                                  <Link
                                    to={`/user/all-products/${this.state.selectedId}`}
                                    className="see-all-btn font-size-14"
                                  >
                                    All Items{" "}
                                    <i className="fas fa-arrow-right" />
                                  </Link>
                                  <time>Posted, 15 days, 12:30</time>
                                </div>
                                <div className="detail-owner-name-bx clearfix">
                                  <div className="detail-owner-img">
                                    {this.state.userDetails[0]
                                      .profilePicture ? (
                                      <img
                                        src={General.showImageNew(
                                          "profile",
                                          this.state.userDetails[0]
                                            .profilePicture
                                        )}
                                        alt={
                                          this.state.userDetails[0]
                                            .profilePicture
                                        }
                                      />
                                    ) : (
                                      <img
                                        src="/images/muser.jpg"
                                        alt="image"
                                      />
                                    )}
                                    {this.state.userDetails[0].isOnline &&
                                    this.state.userDetails[0].isOnline ===
                                      true ? (
                                      <span className="live-dot" />
                                    ) : (
                                        <span className="livestats_useronline"></span>
                                    )}
                                  </div>
                                  <div className="detail-owner-name">
                                    <h2>
                                      {this.state.userDetails[0]
                                        ? this.state.userDetails[0].firstName
                                        : ""}
                                    </h2>
                                    <p>
                                      <span>Last Seen:</span>{" "}
                                      { this.state.SelectedTime
                                        ? moment(
                                          this.state.SelectedTime
                                        ).fromNow()
                                        : ""}
                                    </p>
                                    <div className="rated-star">
                                      <span>Rated</span>
                                      <span>
                                        <strong>5.0</strong>
                                      </span>
                                      <i className="fas fa-star" />
                                      <i className="fas fa-star" />
                                      <i className="fas fa-star" />
                                      <i className="fas fa-star" />
                                      <i className="fas fa-star" />
                                    </div>
                                  </div>
                                </div>
                                <div className="safety-tips-bx">
                                  <h2>Safety Tips</h2>
                                  <ul>
                                    {this.state.safetyTips &&
                                    this.state.safetyTips.length
                                      ? this.state.safetyTips.map(
                                          (data, index) => (
                                            <li key={index}>{data}</li>
                                          )
                                        )
                                      : ""}
                                  </ul>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="bg-light-dark">
                      <div />
                    </section>
                  </div>
                </div>
              </div>
            </div>
            <div className="side-bar-stoper"></div>
          </div>
        </section>
        <section className="bg-light-dark">
          <div></div>
        </section>
      </React.Fragment>
    );
  }
}

export default UserMessageComponent;
