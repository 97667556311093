import React, { Component } from "react";
import General from "Helpers/General";
import Moment from "react-moment";
import moment from "moment";
import socket from "Helpers/Socket";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";
import DatePicker from "react-date-picker";
import Storage from "Helpers/Storage";
import { LinearProgress, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Link, Redirect } from "react-router-dom";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ContentLoader from "react-content-loader";
import MyProfile from "Models/MyProfile";
import { confirmAlert } from "react-confirm-alert"; // Import
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MyProductComponent from "./MyProductComponent";
import MyAchievementComponent from "./MyAchievementComponent";
import MyOrderComponent from "./MyOrderComponent";
import WeeklyDealsProductList from "./WeeklyDealsProductList";
var qs = require("qs");

export class ManageList extends Component {
  constructor(props) {
    super(props);

    const myPros = localStorage.getItem("MyProducts") ? true : false;
    let session_id = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    }).session_id;

    let session_resp = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    }).response;
    //  console.log("response-->23", session_id)
    // console.log("response-->", this.state)

    window.scrollTo(0, 0);

    this.state = {
      headerLogo: localStorage.getItem("HeaderLogo"),
      userId: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["_id"]
        : "",
      products: localStorage.getItem("MyProducts")
        ? JSON.parse(localStorage.getItem("MyProducts"))
        : [],
      activeProductsCount: 0,
      broughtProductsCount: 0,
      soldProductsCount: 0,
      disputeProductsCount: 0,
      achievementProductsCount: 0,
      searchText: "",
      selectedDay: "",
      showSkelaton: myPros,
      showActiveAd: "active",
      showWeeklyDealsSection: false,
      showOrdersSection: false,
      openProductFilter: false,
      ordersData: [],
      allWeekendDeals: [],
      allProductsForWeekendDeals: [],
      session_id: session_id ? session_id : "",
      session_resp: session_resp ? session_resp : "",
      selectedWeekendProducts: [],
      selectedWeekendProductIds: [],
      weekendDealDiscount: 0,
      productSkeleton: [
        { id: 0 },
        { id: 340 },
        { id: 680 },
        { id: 1020 },
        { id: 1360 },
      ],
      isLoading: false,
      expandedAccordion: "accordion1",
    };
  }

  componentDidMount = () => {
    this.getAllProducts();
    this.getAllProductsForWeekendDeals();
    this.getAllWeekendDeals();
    socket.on("orderStatusHandlerResponse", (data) => {
      if (data) {
        console.log("showactivead", this.state.showActiveAd);
        this.getStatusProductHandler2(this.state.showActiveAd);
      }
    });
    if (this.state.session_resp) {
      const currentUrl = window.location.href;
      const newUrl = currentUrl.split("?")[0];
      window.history.replaceState({ path: newUrl }, "", newUrl);
    }
  };

  getAllProducts = () => {
    MyProfile.getApi(
      "APIS_GET_MY_PROFILE_PRODUCT",
      async (response) => {
        Storage.setLocalData("MyProducts", JSON.stringify(response.products));
        //Storage.setLocalData("MyReviews", JSON.stringify(response.reviews));
        //Storage.setLocalData("MyAvgRating", JSON.stringify(response.avgRating));
        //this.setState({avgRating : response.avgRating});
        //this.setState({ allReviews: response.reviews });
        //this.setState({ allReviewsList: response.reviews });
        this.setState({ products: response.products });
        //this.setState({ wishlists: response.wishlists });
        //this.setState({ totalCount: response.totalCount });
        //this.setState({ editLiveSchedule: false });
        this.setState({ showSkelaton: true });
        // active count
        this.setState({
          activeProductsCount: response.products.filter(
            (p) => p.active === true && p.isExpired === false
          ).length,
        });

        /* this.setState({
          activeProductsCount: response.totalCount
        }); */
      },
      true
    );

    const buyerId = this.state.userId;
    MyProfile.getApi(
      "API_GET_ORDERED_STATUS_BY_ID",
      async (res) => {
        if (res) {
          console.log("bought", res);
          this.setState({
            broughtProductsCount: res.boughtCount,
            soldProductsCount: res.soldCount,
            disputeProductsCount: res.disputeCount,
            achievementProductsCount: res.achievementCount,
          });
        }
      },
      { params: { id: buyerId } },
      true
    );
  };

  publishProductHandler = (e, id) => {
    e.preventDefault();
    confirmAlert({
      title: "Confirm to change status",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.setState({ isLoading: true });
            MyProfile.postApiByParams(
              "APIS_PUBLISH_MY_PRODUCT",
              { id },
              async (response) => {
                this.getAllProducts();
                this.setState({ isLoading: false });
              },

              true
            );
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };

  deleteProductHandler = (e, id) => {
    e.preventDefault();

    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.setState({ isLoading: true });
            MyProfile.postApi(
              "APIS_REMOVE_PRODUCT_FROM_MY_PROFILE",
              { id },
              async (response) => {
                this.getAllProducts();
                this.setState({ isLoading: false });
              },
              true
            );
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };

  getAllProductsForWeekendDeals = () => {
    MyProfile.getApi(
      "APIS_GET_ALL_PRODUCTS_FOR_WEEKEND_DEALS",
      async (response) => {
        // console.log(response);
        this.setState({ allProductsForWeekendDeals: response });
      },
      true
    );
  };

  getAllWeekendDeals = () => {
    MyProfile.getApi(
      "APIS_GET_ALL_WEEKEND_DEALS",
      async (response) => {
        this.setState({ allWeekendDeals: response });
      },
      true
    );
  };

  deleteWeekendDealProductHandler = (e, id) => {
    e.preventDefault();

    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.setState({ isLoading: true });
            MyProfile.postApiByParams(
              "APIS_REMOVE_WEEKEND_DEAL_PRODUCT_FROM_MY_PROFILE",
              { id },
              async (response) => {
                this.getAllWeekendDeals();
                this.getAllProductsForWeekendDeals();
                this.setState({ isLoading: false });
              },

              true
            );
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };

  weekendSubmitHandler = (e) => {
    e.preventDefault();
    const { selectedWeekendProductIds, selectedDay, weekendDealDiscount } =
      this.state;

    if (selectedWeekendProductIds.length == 0) {
      document.getElementById("product-text").style.display = "block";
      return;
    }

    if (!weekendDealDiscount || weekendDealDiscount < 0) {
      document.getElementById("discount-list").style.display = "block";
      return;
    }

    if (!selectedDay) {
      document.getElementById("end-date").style.display = "block";
      return;
    }

    MyProfile.postApi(
      "APIS_CREATE_WEEKEND_DEALS",
      {
        products: selectedWeekendProductIds,
        discount: weekendDealDiscount,
        startTime: selectedDay,
      },
      async (response) => {
        this.setState({
          selectedWeekendProductIds: [],
          selectedWeekendProducts: [],
          selectedDay: "",
          weekendDealDiscount: 0,
        });
        this.getAllWeekendDeals();
      },
      true
    );
  };

  getStatusProductHandler = (status) => {
    this.setState({
      showWeeklyDealsSection: false,
      showOrdersSection: false,
      weeklyDealsEndDate: "",
      discountList: [],
      scheduleWeeklyDealsSelectedProducts: [],
      scheduleWeeklyDealsSelectedProductsId: [],
      isLoading: true,
    });

    MyProfile.getApi(
      "APIS_GET_MY_PROFILE_PRODUCT",

      async (response) => {
        switch (status) {
          case "active":
            this.setState({
              //products: response.products.filter((pro) => pro.active === true),
              products: response.products,
              showActiveAd: "active",
            });
            break;
          case "pending":
            this.setState({
              products: response.products.filter((pro) => pro.active === false),
              showActiveAd: "pending",
            });
            break;
          case "expired":
            this.setState({
              products: response.products.filter(
                (p) =>
                  moment().diff(moment(new Date(p.expiredAt), "seconds")) > 0
              ),
              showActiveAd: "expired",
            });
            break;
          default:
            this.setState({ products: response.products, showActiveAd: "all" });
            break;
        }
        this.setState({ isLoading: false });
      },
      true
    );
  };

  getStatusProductHandler2 = (status) => {
    this.setState({
      showWeeklyDealsSection: false,
      showOrdersSection: true,
      weeklyDealsEndDate: "",
      discountList: [],
      scheduleWeeklyDealsSelectedProducts: [],
      scheduleWeeklyDealsSelectedProductsId: [],
      isLoading: true,
    });
    this.setState({ isLoading: true });
    const buyerId = this.state.userId;
    const sellerId = this.state.userId;
    switch (status) {
      case "bought":
        MyProfile.getApi(
          "API_GET_ORDERED_PRODUCT_BY_BUYERID",
          async (res) => {
            if (res) {
              this.setState({
                ordersData: res,
                showActiveAd: "bought",
              });
            }
          },
          { params: { buyerId } },
          true
        );
        break;
      case "sold":
        MyProfile.getApi(
          "API_GET_ORDERED_PRODUCT_BY_BUYERID",
          async (res) => {
            if (res) {
              this.setState({
                ordersData: res,
                showActiveAd: "sold",
              });
            }
          },
          { params: { sellerId } },
          true
        );
        break;
      case "dispute":
        MyProfile.postApiByParams(
          "API_GET_ORDERED_BY_STATUS",
          { id: buyerId },
          async (response) => {
            if (response) {
              this.setState({
                ordersData: response.data,
                showActiveAd: "dispute",
              });
            }
          },
          true
        );
        break;
      case "achievement":
        MyProfile.postApiByParams(
          "API_GET_ACHIEVEMENT_ORDERED",
          { id: buyerId },
          async (response) => {
            if (response) {
              this.setState({
                ordersData: response.data,
                showActiveAd: "achievement",
              });
            }
          },
          true
        );

        break;
      default:
        this.setState({ ordersData: [{}] });
        break;
    }
    this.setState({ isLoading: true });
  };

  searchProductHandler = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    MyProfile.postApiByParams(
      "APIS_GET_MY_PROFILE_PRODUCT_SEARCH",
      { query: this.state.searchText },
      async (response) => {
        this.setState({ products: response.products });
        this.setState({ totalCount: response.products.length });

        // active count
        this.setState({
          activeProductsCount: response.data.filter(
            (p) => p.active === true && p.isExpired === false
          ).length,
        });

        this.setState({ isLoading: false });
      },
      true
    );
  };

  showWeeklyDeals = (type) => {
    this.setState({ showActiveAd: type });
    this.setState({ showWeeklyDealsSection: true });
    this.setState({ showOrdersSection: false });
  };

  weekendDealsHandler = (event, value) => {
    let selectedWeekendProducts = [];
    let selectedWeekendProductIds = [];

    value.forEach((val) => {
      selectedWeekendProducts.push(val);
      selectedWeekendProductIds.push(val._id);
    });

    this.setState({
      selectedWeekendProducts,
      selectedWeekendProductIds,
    });
  };

  handleDayChange = (selectedDay, modifiers, dayPickerInput) => {
    this.setState({
      selectedDay,
    });
  };

  handleAccordionChange = (panel) => (event, isExpanded) => {
    this.setState({
      expandedAccordion: isExpanded ? panel : null,
    });
  };

  render() {
    const {
      searchText,
      products,
      showActiveAd,
      activeProductsCount,
      broughtProductsCount,
      soldProductsCount,
      disputeProductsCount,
      achievementProductsCount,
      isLoading,
      expandedAccordion,
    } = this.state;
    console.log("ordersData", this.state.ordersData, showActiveAd);
    return (
      <>
        {isLoading && (
          <div className="loader-wrapper" id="loader_img">
            <div className="loader">
              <img src={this.state.headerLogo} alt="" />
              <div className="material-spinner"></div>
            </div>
          </div>
        )}
        <div
          className="tab-pane active in this_subManage_Listings"
          id="subtab01"
          role="tabpanel"
        >
          <div className="subtab_prodlist_NEW subtab_prodlist_NEW_forpadd paddint_toppage06">
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-3 subtab_firstmain paddingrigt_none_Cfilter"
                  id="subtab_firstfixed"
                >
                  <div
                    className={`product-sideBar-parent new_perenttop_forspecals ${this.state.openProductFilter ? "active" : ""
                      }`}
                  >
                    <div className="product-listing-sideBar">
                      <div className="sidebarleft_newinner">
                        <div id="filter-closer">
                          <img
                            src="/images/cancel.svg"
                            alt="icon"
                            onClick={() =>
                              this.setState({
                                openProductFilter: false,
                              })
                            }
                          />
                        </div>
                        <div className="product-listing-sideBar-colum sideBar-location  d-none">
                          <div className="form-group mb-0 sideBar-choose-distance">
                            <div className="form-group-icon-bx">
                              <input
                                type="text"
                                value={searchText}
                                onChange={(e) =>
                                  this.setState({
                                    searchText: e.target.value,
                                  })
                                }
                                placeholder="Search for . . ."
                                className="header-input"
                              />
                            </div>
                            <div className="search-btn">
                              <button onClick={this.searchProductHandler}>
                                <img
                                  src="/images/search-icon.png"
                                  alt="search-icon.png"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="sideBar-category sideTabMenu">
                          <Accordion
                            expanded={expandedAccordion === "accordion1"}
                            onChange={this.handleAccordionChange("accordion1")}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              className="product-listing-sideBar-title font-size-16"
                            >
                              <strong> My Ads </strong>
                            </AccordionSummary>
                            <AccordionDetails>
                              <ul
                                className="toggletab-bx nav nav-tabs toggle-bx"
                                role="tablist"
                              >
                                <li
                                  className={
                                    showActiveAd == "active" ? "active" : ""
                                  }
                                >
                                  <a
                                    href={void 0}
                                    className="goclass01"
                                    onClick={() =>
                                      this.getStatusProductHandler("active")
                                    }
                                  >
                                    Active ({activeProductsCount})
                                  </a>
                                </li>
                                <li
                                  className={
                                    showActiveAd == "bought" ? "active" : ""
                                  }
                                >
                                  <a
                                    href={void 0}
                                    className="goclass01"
                                    onClick={() =>
                                      this.getStatusProductHandler2("bought")
                                    }
                                  >
                                    Bought({broughtProductsCount})
                                  </a>
                                </li>
                                <li
                                  className={
                                    showActiveAd == "sold" ? "active" : ""
                                  }
                                >
                                  <a
                                    href={void 0}
                                    className="goclass01"
                                    onClick={() =>
                                      this.getStatusProductHandler2("sold")
                                    }
                                  >
                                    Sold ({soldProductsCount})
                                  </a>
                                </li>
                                <li
                                  className={
                                    showActiveAd == "dispute" ? "active" : ""
                                  }
                                >
                                  <a
                                    href={void 0}
                                    className="goclass01"
                                    onClick={() =>
                                      this.getStatusProductHandler2("dispute")
                                    }
                                  >
                                    Dispute ({disputeProductsCount})
                                  </a>
                                </li>
                                <li
                                  className={
                                    showActiveAd == "achievement"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  <a
                                    href={void 0}
                                    className="goclass01"
                                    onClick={() =>
                                      this.getStatusProductHandler2(
                                        "achievement"
                                      )
                                    }
                                  >
                                    Achievement ({achievementProductsCount})
                                  </a>
                                </li>
                              </ul>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            expanded={expandedAccordion === "accordion2"}
                            onChange={this.handleAccordionChange("accordion2")}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel2a-header"
                              className="product-listing-sideBar-title font-size-16"
                            >
                              <strong> Weekly Deals</strong>
                            </AccordionSummary>
                            <AccordionDetails>
                              <ul
                                className="toggletab-bx nav nav-tabs toggle-bx"
                                role="tablist"
                              >
                                <li>
                                  <a
                                    href={void 0}
                                    onClick={() => this.showWeeklyDeals()}
                                  >
                                    <strong>Show on Weekly Deals</strong>
                                  </a>
                                </li>
                              </ul>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.showWeeklyDealsSection === false &&
                  this.state.showOrdersSection === false ? (
                  <div className="col-lg-9 subtab_secondmain">
                    <div className="fillter_box">
                      <div
                        id="filter-toggler"
                        className={
                          showActiveAd == "active" ? "btnx btn-secondaryx mx-2 active" : "btnx btn-secondaryx mx-2 btn-inactive"
                        }
                        onClick={() => this.getStatusProductHandler("active")}
                      >
                        Active ({activeProductsCount})
                      </div>
                      <div
                        id="filter-toggler"
                        className={
                          showActiveAd == "bought" ? "btnx btn-secondaryx mx-2 active" : "btnx btn-secondaryx mx-2 btn-inactive"
                        }
                        onClick={() => this.getStatusProductHandler2("bought")}
                      >
                        Bought ({broughtProductsCount})
                      </div>
                      <div
                        id="filter-toggler"
                        className={
                          showActiveAd == "sold" ? "btnx btn-secondaryx mx-2 active" : "btnx btn-secondaryx mx-2 btn-inactive"
                        }
                        onClick={() => this.getStatusProductHandler2("sold")}
                      >
                        Sold ({soldProductsCount})
                      </div>
                      <div
                        id="filter-toggler"
                        className={
                          showActiveAd == "dispute" ? "btnx btn-secondaryx mx-2 active" : "btnx btn-secondaryx mx-2 btn-inactive"
                        }
                        onClick={() => this.getStatusProductHandler2("dispute")}
                      >
                        Dispute ({disputeProductsCount})
                      </div>
                      <div
                        id="filter-toggler"
                        className={
                          showActiveAd == "achievement" ? "btnx btn-secondaryx mx-2 active" : "btnx btn-secondaryx mx-2 btn-inactive"
                        }
                        onClick={() =>
                          this.getStatusProductHandler2("achievement")
                        }
                      >
                        Achievement ({achievementProductsCount})
                      </div>
                      <div
                        id="filter-toggler"
                        className={
                          showActiveAd == "weeklydeal" ? "btnx btn-secondaryx mx-2 active" : "btnx btn-secondaryx mx-2 btn-inactive"
                        }
                        onClick={() => this.showWeeklyDeals('weeklydeal')}
                      >
                        Weekly Deal
                      </div>
                    </div>
                    <div className="Jcompny_allprod">
                      <div className="Jtaballp_content atlistingside_new">
                        {products && products.length > 0 ? (
                          products.map((pro) => (
                            <div className="row" key={pro._id}>
                              <MyProductComponent
                                product={pro}
                                deleteProductHandler={this.deleteProductHandler}
                                publishProductHandler={
                                  this.publishProductHandler
                                }
                              />
                            </div>
                          ))
                        ) : this.state.showSkelaton ? (
                          <div className="alert alert-danger col-lg-12">
                            <strong>Info!</strong> Nothing here.
                          </div>
                        ) : (
                          this.state.productSkeleton.map(function (
                            rowsskeleton
                          ) {
                            return (
                              <ContentLoader
                                viewBox="0 0 1380 300"
                                height={340}
                                width={1380}
                                key={rowsskeleton.id}
                              >
                                <rect
                                  x="20"
                                  y={rowsskeleton.id}
                                  rx="10"
                                  ry="10"
                                  width="300"
                                  height="300"
                                />
                                <rect
                                  x="360"
                                  y={rowsskeleton.id}
                                  rx="10"
                                  ry="10"
                                  width="1000"
                                  height="300"
                                />
                              </ContentLoader>
                            );
                          })
                        )}
                      </div>
                    </div>
                  </div>
                ) : this.state.showWeeklyDealsSection === true ? (
                  <>
                    <div className="col-lg-9 subtab_secondmain">
                      <div className="fillter_box">
                        <div
                          id="filter-toggler"
                          className={
                            showActiveAd == "active" ? "btnx btn-secondaryx mx-2 active" : "btnx btn-secondaryx mx-2 btn-inactive"
                          }
                          onClick={() => this.getStatusProductHandler("active")}
                        >
                          Active ({activeProductsCount})
                        </div>
                        <div
                          id="filter-toggler"
                          className={
                            showActiveAd == "bought" ? "btnx btn-secondaryx mx-2 active" : "btnx btn-secondaryx mx-2 btn-inactive"
                          }
                          onClick={() =>
                            this.getStatusProductHandler2("bought")
                          }
                        >
                          Bought ({broughtProductsCount})
                        </div>
                        <div
                          id="filter-toggler"
                          className={
                            showActiveAd == "sold" ? "btnx btn-secondaryx mx-2 active" : "btnx btn-secondaryx mx-2 btn-inactive"
                          }
                          onClick={() => this.getStatusProductHandler2("sold")}
                        >
                          Sold ({soldProductsCount})
                        </div>
                        <div
                          id="filter-toggler"
                          className={
                            showActiveAd == "dispute" ? "btnx btn-secondaryx mx-2 active" : "btnx btn-secondaryx mx-2 btn-inactive"
                          }
                          onClick={() =>
                            this.getStatusProductHandler2("dispute")
                          }
                        >
                          Dispute ({disputeProductsCount})
                        </div>
                        <div
                          id="filter-toggler"
                          className={
                            showActiveAd == "achievement" ? "btnx btn-secondaryx mx-2 active" : "btnx btn-secondaryx mx-2 btn-inactive"
                          }
                          onClick={() =>
                            this.getStatusProductHandler2("achievement")
                          }
                        >
                          Achievement ({achievementProductsCount})
                        </div>
                        <div
                          id="filter-toggler"
                          className={
                            showActiveAd == "weeklydeal" ? "btnx btn-secondaryx mx-2 active" : "btnx btn-secondaryx mx-2 btn-inactive"
                          }
                          onClick={() => this.showWeeklyDeals('weeklydeal')}
                        >
                          Weekly Deal
                        </div>
                      </div>
                      <div className="formcontdetail_inner withbg_white mt-4 mt-md-0">
                        <div className="tab-content subtab_tabcont weekly_deals_block p-3">
                          <div className="py-3 row align-items-center next_block">
                            <div className="Schedule_live_heading text-left   col-lg-5  mb-2 mb-lg-0 pb-0">
                              {" "}
                              Add Items to Weekly Deals{" "}
                            </div>
                            <div className="col-lg-7">
                              <div className="header-input-icon header-input-icon_block w-100 ">
                                <Autocomplete
                                  multiple
                                  onChange={this.weekendDealsHandler}
                                  options={
                                    this.state.allProductsForWeekendDeals
                                  }
                                  getOptionLabel={(option) =>
                                    option.productName
                                  }
                                  value={this.state.selectedWeekendProducts}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="standard"
                                      className="font-size-16"
                                      label="Select Item"
                                    />
                                  )}
                                />
                              </div>
                              <p
                                id="product-text"
                                className="text-danger"
                                style={{ display: "none" }}
                              >
                                Product is required*
                              </p>
                            </div>{" "}
                          </div>
                          <div className="pt-3 row align-items-start next_block">
                            <div className="Schedule_live_heading text-left   col-lg-5  mb-2 mb-lg-0 pb-0">
                              {" "}
                              Discount{" "}
                            </div>
                            <div className="col-lg-7">
                              <div className="header-input-icon header-input-icon_block w-100 my_profile_Weekly_deal">
                                <input
                                  type="number"
                                  name="options"
                                  className="fuilddesh"
                                  placeholder="%"
                                  pattern="^[0-9]+$"
                                  min={0}
                                  value={this.state.weekendDealDiscount}
                                  onChange={(e) =>
                                    this.setState({
                                      weekendDealDiscount: e.target.value,
                                    })
                                  }
                                />
                                <div>
                                  <p
                                    id={"discount-list"}
                                    className="text-danger discount-option-required"
                                    style={{
                                      display: "none",
                                    }}
                                  >
                                    Invalid Discount is value*
                                  </p>
                                </div>
                              </div>
                            </div>{" "}
                          </div>
                          <div className="py-3 row align-items-center next_block">
                            <div className="Schedule_live_heading text-left   col-lg-5 mb-2 mb-lg-0 pb-0">
                              {" "}
                              Select Start Date{" "}
                            </div>
                            <div className="col-lg-7">
                              <div className="header-input-icon header-input-icon_block w-100 my_profile_Weekly_deal date_pic">
                                <DayPickerInput
                                  format="DD/MM/YYYY"
                                  formatDate={formatDate}
                                  placeholder="DD/MM/YYYY"
                                  value={this.state.selectedDay}
                                  onDayChange={this.handleDayChange}
                                  dayPickerProps={{
                                    selectedDays: this.state.selectedDay,
                                    disabledDays: {
                                      daysOfWeek: [1, 2, 3, 4],
                                    },
                                  }}
                                  style={{ display: "block" }}
                                />

                                <div className="cal_icon">
                                  <i className="far fa-calendar-alt"></i>
                                </div>
                              </div>
                              <p
                                id="end-date"
                                className="text-danger"
                                style={{ display: "none" }}
                              >
                                Start Date is required*
                              </p>
                            </div>{" "}
                          </div>
                          <div className="py-4 text-center">
                            <button
                              type="button"
                              className="btnx btn-secondaryx mr-3 font-size-14"
                              onClick={this.getStatusProductHandler}
                            >
                              Back
                            </button>
                            <button
                              type="button"
                              onClick={this.weekendSubmitHandler}
                              className="btnx btn-secondaryx font-size-14"
                            >
                              Add
                            </button>
                          </div>
                          {this.state.allWeekendDeals &&
                            this.state.allWeekendDeals.map((pro) => (
                              <WeeklyDealsProductList
                                product={pro}
                                key={pro._id}
                                deleteProductHandler={
                                  this.deleteWeekendDealProductHandler
                                }
                              />
                            ))}
                        </div>
                      </div>
                    </div>
                  </>
                ) : this.state.showOrdersSection === true ? (
                  <>
                    <div className="col-lg-9 subtab_secondmain">
                      <div className="fillter_box">
                        <div
                          id="filter-toggler"
                          className={
                            showActiveAd == "active" ? "btnx btn-secondaryx mx-2 active" : "btnx btn-secondaryx mx-2 btn-inactive"
                          }
                          onClick={() => this.getStatusProductHandler("active")}
                        >
                          Active ({activeProductsCount})
                        </div>
                        <div
                          id="filter-toggler"
                          className={
                            showActiveAd == "bought" ? "btnx btn-secondaryx mx-2 active" : "btnx btn-secondaryx mx-2 btn-inactive"
                          }
                          onClick={() =>
                            this.getStatusProductHandler2("bought")
                          }
                        >
                          Bought ({broughtProductsCount})
                        </div>
                        <div
                          id="filter-toggler"
                          className={
                            showActiveAd == "sold" ? "btnx btn-secondaryx mx-2 active" : "btnx btn-secondaryx mx-2 btn-inactive"
                          }
                          onClick={() => this.getStatusProductHandler2("sold")}
                        >
                          Sold ({soldProductsCount})
                        </div>
                        <div
                          id="filter-toggler"
                          className={
                            showActiveAd == "dispute" ? "btnx btn-secondaryx mx-2 active" : "btnx btn-secondaryx mx-2 btn-inactive"
                          }
                          onClick={() =>
                            this.getStatusProductHandler2("dispute")
                          }
                        >
                          Dispute ({disputeProductsCount})
                        </div>
                        <div
                          id="filter-toggler"
                          className={
                            showActiveAd == "achievement" ? "btnx btn-secondaryx mx-2 active" : "btnx btn-secondaryx mx-2 btn-inactive"
                          }
                          onClick={() =>
                            this.getStatusProductHandler2("achievement")
                          }
                        >
                          Achievement ({achievementProductsCount})
                        </div>
                        <div
                          id="filter-toggler"
                          className={
                            showActiveAd == "weeklydeal" ? "btnx btn-secondaryx mx-2 active" : "btnx btn-secondaryx mx-2 btn-inactive"
                          }
                          onClick={() => this.showWeeklyDeals('weeklydeal')}
                        >
                          Weekly Deal
                        </div>
                      </div>
                      <div className="Jcompny_allprod">
                        <div className="Jtaballp_content atlistingside_new">
                          {this.state.ordersData &&
                            this.state.ordersData.orderData &&
                            this.state.ordersData.orderData.length > 0 &&
                            this.state.ordersData.orderData.map((order) => (
                              <div className="row" key={order._id}>
                                {
                                  order.product_id && (
                                    <MyOrderComponent
                                      order={order}
                                      status={
                                        this.state.userId === order.buyer_id
                                          ? "Bought"
                                          : "Sold"
                                      }
                                      recordType={showActiveAd}
                                      product={order.product_id}
                                      deleteProductHandler={
                                        this.deleteProductHandler
                                      }
                                      publishProductHandler={
                                        this.publishProductHandler
                                      }
                                    />
                                  )
                                }
                              </div>
                            ))}
                          {this.state.ordersData &&
                            this.state.ordersData.achievement &&
                            this.state.ordersData.achievement.length > 0 &&
                            this.state.ordersData.achievement.map((product) => (
                              <div
                                className="row"
                                key={`achive_${product._id}`}
                              >
                                <MyAchievementComponent
                                  product={product}
                                  deleteProductHandler={
                                    this.deleteProductHandler
                                  }
                                  publishProductHandler={
                                    this.publishProductHandler
                                  }
                                />
                              </div>
                            ))}
                          {(this.state.ordersData &&
                            this.state.ordersData.orderData &&
                            this.state.ordersData.orderData.length > 0) ||
                            (this.state.ordersData &&
                              this.state.ordersData.achievement &&
                              this.state.ordersData.achievement.length > 0) ? (
                            ""
                          ) : this.state.showSkelaton ? (
                            <div className="alert alert-danger col-lg-12">
                              <strong>Info!</strong> Nothing here.
                            </div>
                          ) : (
                            this.state.productSkeleton.map(function (
                              rowsskeleton
                            ) {
                              return (
                                <ContentLoader
                                  viewBox="0 0 1380 300"
                                  height={340}
                                  width={1380}
                                  key={rowsskeleton.id}
                                >
                                  <rect
                                    x="20"
                                    y={rowsskeleton.id}
                                    rx="10"
                                    ry="10"
                                    width="300"
                                    height="300"
                                  />
                                  <rect
                                    x="360"
                                    y={rowsskeleton.id}
                                    rx="10"
                                    ry="10"
                                    width="1000"
                                    height="300"
                                  />
                                </ContentLoader>
                              );
                            })
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default ManageList;