import General from "Helpers/General";
import React, { Component } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import MyProfile from "Models/MyProfile";
import DatePicker from "react-date-picker";
import socket from "Helpers/Socket";
import LiveReturnModalComponent from "./LiveReturnModalComponent";
import QB from "Helpers/Quickblox";

export class BoughtLiveAuction extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.state = {
      userId: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["_id"]
        : "",
      showModal: false,
      modalProducts: [],
      bidEnded: "",
      loginUserId: MyProfile.getAuthUserId(),
      modalIsOpen: false,
      returnModalIsOpen: false,
      shippingNote: "",
      returnShippingNote: "",
      expectedDeliveryDate: new Date(),
      returnExpectedDeliveryDate: new Date(),
      showAddressModal: false,
      address: "",
      boughtAuctionProducts: [],
      isLoading: false,
      isModalOpen: false,
    };
    this.handleShippingNote = this.handleShippingNote.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
    this.handleReturnShippingNote = this.handleReturnShippingNote.bind(this);
    this.returnsubmitHandler = this.returnsubmitHandler.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }
  handleOpenModal() {
    this.setState({ isModalOpen: true });
  }

  handleCloseModal() {
    this.setState({ isModalOpen: false });
  }

  componentDidMount = () => {
    this.getAllBoughtAuctionProducts();
  };

  getAllBoughtAuctionProducts = () => {
    MyProfile.postApi(
      "API_GET_USER_BOUGHT_AUCTION_PRODUCTS",
      {},
      async (response) => {
        this.setState({
          boughtAuctionProducts: response.boughtAuctionProducts,
        });
        this.setState({ isLoading: false });
      },
      true
    );
  };

  dateTimeConverson = (dateObj) => {
    const dateTime = new Date(dateObj);
    return dateTime.toLocaleString("en-ZA", { hour12: true });
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };
  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  submitHandler = (e) => {
    e.preventDefault();
    const { expectedDeliveryDate, shippingNote } = this.state;

    if (!expectedDeliveryDate) {
      document.getElementById("expected-date").style.display = "block";
      return;
    }
    if (shippingNote.trim() === "") {
      document.getElementById("shipping-note").style.display = "block";
      return;
    }

    let datestring =
      expectedDeliveryDate.getFullYear() +
      "-" +
      (expectedDeliveryDate.getMonth() + 1) +
      "-" +
      expectedDeliveryDate.getDate();

    let dateExpect = this.dateTimeConverson(expectedDeliveryDate);

    MyProfile.postApi(
      "APIS_SUBMIT_EXPECTED_DELIVERY_DATE_LIVE_AUCTION",
      {
        id: this.props.productData._id,
        expected_delivery_date: datestring,
        shipping_note: shippingNote,
        status: 2,
      },
      async (response, status) => {
        if (status === "success") {
          let { productData } = this.props;
          productData["status"] = 2;
          productData["expected_delivery_date"] = expectedDeliveryDate;
          this.setState({ productData: productData });
          socket.emit("orderStatusHandler", productData);
          this.setState({ modalIsOpen: false });
          // console.log("response",response);
          this.sendAutoMessageOnChat(
            response.buyer_id.email,
            "Your order has been shipped for product " +
            response.productId.productName +
            " and expected delivery date is " +
            dateExpect +
            " with notes (" +
            shippingNote +
            ")"
          );
        }
      },
      true
    );
  };
  returnsubmitHandler = (e) => {
    e.preventDefault();
    const { returnExpectedDeliveryDate, returnShippingNote } = this.state;

    if (!returnExpectedDeliveryDate) {
      document.getElementById("expected-date").style.display = "block";
      return;
    }
    if (returnShippingNote.trim() === "") {
      document.getElementById("shipping-note").style.display = "block";
      return;
    }

    let datestring =
      returnExpectedDeliveryDate.getFullYear() +
      "-" +
      (returnExpectedDeliveryDate.getMonth() + 1) +
      "-" +
      returnExpectedDeliveryDate.getDate();

    let dateExpect = this.dateTimeConverson(returnExpectedDeliveryDate);

    MyProfile.postApi(
      "APIS_SUBMIT_RETURN_SHIPPING_NOTE_LIVE_AUCTION",
      {
        id: this.props.productData._id,
        return_shipping_note: returnShippingNote,
        return_expected_delivery_date: datestring,
        status: 10,
      },
      async (response, status) => {
        if (status === "success") {
          let { productData } = this.props;
          productData["status"] = 10;
          productData["return_expected_delivery_date"] =
            returnExpectedDeliveryDate;
          this.setState({ productData: productData });
          socket.emit("orderStatusHandler", productData);
          this.setState({ returnShippedModal: false });

          // this.sendAutoMessageOnChat(response.buyer_id.email, 'Your order has been shipped for product '+response.product_id.productName+' and expected delivery date is '+ dateExpect+' with notes ('+shippingNote+')');
        }
      },
      true
    );
  };

  selectExpDeliveryData = (date) => {
    this.setState({ expectedDeliveryDate: date });
  };
  selectReturnExpDeliveryData = (date) => {
    this.setState({ returnExpectedDeliveryDate: date });
  };

  handleShippingNote = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  handleReturnShippingNote = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleChildReq = (e) => {
    let { productData } = this.props;
    productData["status"] = 5;
    if (e.reson !== null) {
      productData["reson_id"] = e.reson;
    } else {
      productData["other_reson"] = e.other_reson;
    }

    this.setState({ productData: productData, returnModalIsOpen: false });
    socket.emit("orderStatusHandler", productData);
  };

  handleChildCloseReq = () => {
    this.setState({ returnModalIsOpen: false });
  };

  returnOrderStatusHandler = (return_status, id) => {
    MyProfile.postApi(
      "API_CHANGE_LIVE_AUCTION_RETURN_STATUS",
      { id, return_status },
      async (res, status) => {
        if (status === "success") {
          let { productData } = this.props;
          productData["return_status"] = return_status;
          this.setState({ productData: productData });
          socket.emit("orderStatusHandler", productData);
          let msg =
            "Return Request Reject Of Product " + res.productId.productName;
          this.sendAutoMessageOnChat(res.buyer_id.email, msg);
        }
      },
      true
    );
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.returnAddressHandler(1);
  };

  returnAddressHandler = (status) => {
    const { address } = this.state;
    const id = this.props.productData._id;
    const return_status = "Accepted";
    let productReturn = 0;
    if (status == 1) {
      productReturn = 1;
      if (address.trim() == "") {
        document.getElementById("return-address").style.display = "block";
        return;
      }
    }

    MyProfile.postApi(
      "API_CHANGE_LIVE_AUCTION_RETURN_ADDRESS",
      { id, return_status, address, productReturn },
      async (res, status) => {
        if (status === "success") {
          let { productData } = this.props;
          productData["return_status"] = res.return_status;
          productData["return_address"] = res.return_address;
          productData["is_refunded"] = res.is_refunded;
          this.setState({ productData: productData, showAddressModal: false });
          socket.emit("orderStatusHandler", productData);
          let msg =
            "Return Request Accepted Of Product " + res.productId.productName;
          this.sendAutoMessageOnChat(res.buyer_id.email, msg);
        }
      },
      true
    );
  };
  orderRefund = (orderId, orderStatus) => {
    MyProfile.postApi(
      "API_ORDER_REFUND_LIVE_AUCTION",
      { id: orderId, status: orderStatus },
      async (res, status) => {
        if (status === "success") {
          let { productData } = this.props;
          productData["status"] = 11;
          this.setState({ productData: productData });
          socket.emit("orderStatusHandler", productData);
          let msg = "Order Refunded Successfully " + res.product_id.productName;
          this.sendAutoMessageOnChat(res.buyer_id.email, msg);
        }
      },
      true
    );
  };

  checkEndTimeToCancel = (productData) => {
    console.log("ordertime", productData.orderedTime)
    if (productData.orderedTime) {
      const originalDate = new Date(productData.orderedTime);
      const newDate = new Date(originalDate.getTime() + 60 * 60 * 1000);
      console.log("originalDate", originalDate);
      console.log("newDate", newDate);
      const formattedDate = newDate?.toISOString();
      const formattedDateAsDate = new Date(formattedDate);
      // console.log("formattedDateAsDate",formattedDateAsDate)
      const currentTime = new Date();
      // console.log("currentTime",currentTime)
      let test = currentTime < formattedDateAsDate;
      // console.log("check time",test)
      return currentTime < formattedDateAsDate;
    }
  };

  sendAutoMessageOnChat = (email, msg) => {
    var searchParams = { login: email };
    QB.users.get(searchParams, function (error, user) {
      if (!error) {
        let params = {
          type: 3,
          occupants_ids: [user.id],
        };

        QB.chat.dialog.create(params, (error, dialog) => {
          if (!error) {
            let message = {
              type: "chat",
              body: msg,
              extension: {
                save_to_history: 1,
                dialog_id: dialog._id,
              },
              markable: 1,
            };
            QB.chat.send(user.id, message);
          } else {
            console.log("Dialog Create Error", error);
          }
        });
      }
    });
  };

  render() {
    const { productData, canEdit, loginUser } = this.props;
    const { showModal, modalProducts, userId, boughtAuctionProducts, isModalOpen } =
      this.state;
    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      },
    };

    const orderStatusHandler = (orderId, orderStatus) => {
      if (orderStatus) {
        if (Number(orderStatus) === 2) {
          this.openModal();
          return;
        }

        if (Number(orderStatus) === 5) {
          this.setState({ returnModalIsOpen: true });
          return;
        }
        if (Number(orderStatus) === 11) {
          this.orderRefund(orderId, orderStatus);
          return;
        }

        MyProfile.postApi(
          "API_CHANGE_LIVE_AUCTION_STATUS",
          { id: orderId, status: orderStatus },
          async (res, status) => {
            if (status === "success") {
              productData["status"] = Number(orderStatus);
              this.setState({ productData: productData });
              socket.emit("orderStatusHandler", productData);
            }
          },
          true
        );
      }
    };

    const sellerRequestHandler = (id, sellerRequest) => {
      if (sellerRequest) {
        MyProfile.postApi(
          "API_CHANGE_LIVE_AUCTION_SELLER_REQUEST_STATUS",
          { id, sellerRequest },
          async (res, status) => {
            if (status === "success") {
              productData["sellerRequest"] = Number(sellerRequest);
              this.setState({ productData: productData });
            }
          },
          true
        );
      }
    };
    const returnShipped = (orderId, status) => {
      this.setState({ returnShippedModal: true });
    };
    return (
      <div key={`upcomingLive_${productData._id}`}>
        <div
          className="col-lg-12 listside_newperent desktop_view"
          key={`upcomingLive_${productData._id}`}
        >
          <div className="product-listing-product">
            <div className="row">
              <div className="col-12 col-sm-3">
                <div className="product-img-bx">
                  {productData?.products && (
                    <Link
                      to={MyProfile.isLogin() ? {
                        pathname: "/livestreamingProduct/" +
                          productData.streamId,
                        query: {
                          productId: productData.products.productSlug,
                        },
                      } : "/upcomingLiveStreamingProduct/" +
                      productData.products.productSlug +
                      "/" +
                      productData.streamId
                      }
                    >
                      {productData.products.adType &&
                        productData.products.adType === "Featured" && (
                          <div className="specialtags">
                            <span
                              className={
                                (productData.products.adType === "Featured"
                                  ? "black"
                                  : "green") + "_special"
                              }
                            >
                              {productData.products.adType}{" "}
                            </span>
                          </div>
                        )}
                      {productData.products.productMainImage ? (
                        <img
                          src={General.showImage(
                            "products/resize-180-180",
                            productData.products.productMainImage
                          )}
                          onError={(e) =>
                            (e.target.src = "/images/no-image.jpg")
                          }
                          alt={productData.products.productName}
                        />
                      ) : (
                        <img src="/images/no-image.jpg" alt="product" />
                      )}

                      <div className="totalimg_sectab">
                        {productData.products.productImages
                          ? productData.products.productImages.length
                          : 0}{" "}
                        <i className="fas fa-camera"></i>
                      </div>
                    </Link>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-9 my-productColBg">
                <div className="product-outer-bx productBig-outer-bx">
                  <div className="product-name-bx content_gobottom_new">
                    <div className="main_div_crd">
                      <div className="main_div_left">
                        {productData?.products && (
                          <Link
                            to={MyProfile.isLogin() ? {
                              pathname: "/livestreamingProduct/" +
                                productData.streamId,
                              query: {
                                productId: productData.products.productSlug,
                              },
                            } : "/upcomingLiveStreamingProduct/" +
                            productData.products.productSlug +
                            "/" +
                            productData.streamId
                            }
                          >
                            <h4>{productData?.products.productName}</h4>
                          </Link>
                        )}
                        <h5>
                          Duration: {productData.live_stream?.length} mins
                        </h5>
                        <h6>
                          <span>
                          </span>
                        </h6>
                        {productData?.products && (
                          <ul className="listofsubtabbx">
                            <span
                              className="labltable lablesct view_btn"
                              style={{ cursor: "pointer", marginBottom: "5px" }}
                              onClick={this.handleOpenModal}
                            >
                              <i className="far fa-eye"></i> View Address
                            </span>
                            <Modal
                              isOpen={isModalOpen}
                              onRequestClose={this.handleCloseModal}
                              contentLabel="Buyer Protection"
                              className="modal-content"
                              overlayClassName="modal-overlay"
                            >
                              <button onClick={this.handleCloseModal} className="shipCloseBtn">
                                <i className="fa fa-times "></i>
                              </button>
                              <div className="product-outer-bx productBig-outer-bx">
                                <div className="product-name-bx content_gobottom_new prdNewRow_row">
                                  <div className="viewAddressPopup viewAddressPopupTrack">
                                    {productData.products.delivery === 2 ? (
                                      <h5>
                                        <span>Shipping Address : </span>
                                        {`${productData?.registerbids?.deliveryAddress
                                          ?.name
                                          } ${productData?.registerbids?.deliveryAddress
                                            ?.address?.line1
                                          } ${productData?.registerbids?.deliveryAddress
                                            ?.address?.line2
                                          } ${productData?.registerbids?.deliveryAddress
                                            ?.address?.city
                                          } ${productData?.registerbids?.deliveryAddress
                                            ?.address?.country == "GB"
                                            ? "United Kingdom"
                                            : productData?.registerbids?.deliveryAddress
                                              ?.address?.country
                                          } ${productData?.registerbids?.deliveryAddress
                                            ?.address?.postal_code
                                          }`}
                                      </h5>
                                    ) :
                                      <span>No Address Found</span>
                                    }
                                  </div>
                                </div>
                              </div>
                            </Modal>
                            <li>
                              Auction Ended :{" "}
                              {this.dateTimeConverson(productData.endTime)}
                            </li>
                            {productData.user._id == userId ? (
                              <>
                                <li>
                                  Bought Out By :{" "}
                                  {productData?.winner?.users.firstName +
                                    " " +
                                    productData?.winner?.users.lastName}
                                </li>
                                <li>
                                  Winning Price : {productData?.winner?.amount}
                                </li>
                              </>
                            ) : (
                              <>
                                <li>
                                  Seller :{" "}
                                  {productData?.user.firstName +
                                    " " +
                                    productData?.user.lastName}
                                </li>
                              </>
                            )}
                            {productData?.expected_delivery_date &&
                              productData.status === 2 && (
                                <li>
                                  <strong>Expected Delivery</strong>
                                  <span>
                                    <Moment format="YYYY/MM/DD">
                                      {productData?.expected_delivery_date}
                                    </Moment>
                                  </span>
                                </li>
                              )}
                          </ul>
                        )}
                        {productData.status === 5 && (
                          <ul>
                            <li>
                              <strong>Return Reson :</strong>
                              {productData?.reson_id !== null ? (
                                <span>{productData?.reson_id["name"]}</span>
                              ) : (
                                <span>{productData?.other_reson}</span>
                              )}
                            </li>
                            {productData?.return_address &&
                              productData.return_address !== null && (
                                <li>
                                  <strong>Return Address : </strong>
                                  <span>{productData.return_address}</span>
                                </li>
                              )}
                            <li>
                              <div className="mt-2">
                                {productData?.return_status &&
                                  productData?.return_status != null && (
                                    <>
                                      <span
                                        className={`labltable lablesct ${productData.return_status}`}
                                      >
                                        {productData.return_status}
                                      </span>
                                    </>
                                  )}

                                {productData.userId ===
                                  this.state.loginUserId &&
                                  productData?.return_status === null && (
                                    <>
                                      <button
                                        className="btn-sm accept-theme mr-1"
                                        onClick={() =>
                                          this.setState({
                                            showAcceptModal: true,
                                          })
                                        }
                                      >
                                        Accept
                                      </button>
                                      <button
                                        className="btn-sm reject-theme"
                                        onClick={() =>
                                          this.returnOrderStatusHandler(
                                            "Rejected",
                                            productData?._id
                                          )
                                        }
                                      >
                                        Reject
                                      </button>
                                    </>
                                  )}
                              </div>
                            </li>
                          </ul>
                        )}
                      </div>
                      <div className="main_div_right">
                        <div className="subtab_edit_ mt-2_ coustom_right_block">
                          <div className="subtab_editlabl btn_div">
                            <span
                              className="labltable lablesct view_btn"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                this.setState({
                                  modalProducts: productData?.bidders ?? [],
                                  bidEnded: productData.endTime,
                                  showModal: true,
                                })
                              }
                            >
                              <i className="far fa-eye"></i> View
                            </span>
                            <div className="subtab_editlabl">
                              <span className="labltable lablesct">
                                {(() => {
                                  if (productData.status === 1) {
                                    return "Order Posted";
                                  } else if (productData.status === 2) {
                                    return "Shipped";
                                  } else if (productData.status === 3) {
                                    return "Delivered";
                                  } else if (productData.status === 4) {
                                    return "Completed";
                                  } else if (
                                    [11, 8, 9].includes(productData.status) ||
                                    (productData.status === 5 &&
                                      productData.is_refunded === 1)
                                  ) {
                                    return "Suspend";
                                  } else if (productData.status === 6) {
                                    return "Order Disputed";
                                  } else if (productData.status === 7) {
                                    return "Cancelled";
                                  } else if (
                                    [5].includes(productData.status) &&
                                    productData?.return_status == "Accepted" &&
                                    productData.userId !==
                                    this.state.loginUserId &&
                                    productData.is_refunded == 0
                                  ) {
                                    return "Return Order";
                                  } else if (productData.status === 10) {
                                    return "Return Order Shipped";
                                  } else if (
                                    [5].includes(productData.status) &&
                                    productData?.return_status == null &&
                                    productData.is_refunded == 0
                                  ) {
                                    return "Return Requested";
                                  } else if (
                                    [5].includes(productData.status) &&
                                    productData?.return_status == "Accepted" &&
                                    productData.userId ===
                                    this.state.loginUserId &&
                                    productData.is_refunded == 0
                                  ) {
                                    return "Return Accepted";
                                  } else if (
                                    [5].includes(productData.status) &&
                                    productData?.return_status == "Rejected"
                                  ) {
                                    return "Return Rejected";
                                  }
                                })()}
                              </span>
                            </div>
                          </div>
                          <div className="subtab_edit newPrdList_col-2 blankspace">
                            {(() => {
                              if (
                                productData.userId === this.state.loginUserId
                              ) {
                                if (productData.sellerRequest === 1) {
                                  return (
                                    <>
                                      <div className="subtab_editlabl">
                                        <span className="labltable lablesct mb-2">
                                          Request Money
                                        </span>
                                      </div>
                                    </>
                                  );
                                } else if (productData.sellerRequest === 2)
                                  return (
                                    <>
                                      <div className="subtab_editlabl">
                                        <span className="labltable lablesct mb-2">
                                          Release Money
                                        </span>
                                      </div>
                                    </>
                                  );
                              }
                            })()}

                            <div className="subtab_editlabl select_bx">
                              {productData.status === 1 &&
                                productData.userId ===
                                this.state.loginUserId && (
                                  <select
                                    className="custom-select form-control select_drop"
                                    value={productData?.status}
                                    onChange={(e) =>
                                      orderStatusHandler(
                                        productData._id,
                                        e.target.value
                                      )
                                    }
                                  >
                                    <option value="">Select Option</option>
                                    {productData.status === 1 && (
                                      <option value="7">Cancel Order</option>
                                    )}
                                    {productData.status === 1 &&
                                      productData.products.delivery === 2 && (
                                        <option value="2">Shipped </option>
                                      )}
                                  </select>
                                )}
                              {productData.status === 6 &&
                                productData?.return_status === "Rejected" &&
                                productData.userId ===
                                this.state.loginUserId && (
                                  <select
                                    className="custom-select form-control select_drop"
                                    value={productData?.status}
                                    onChange={(e) =>
                                      orderStatusHandler(
                                        productData._id,
                                        e.target.value
                                      )
                                    }
                                  >
                                    <option value="">Select Option</option>
                                    <option value="8">Resolve for Buyer</option>
                                  </select>
                                )}
                              {([1].includes(productData.status) ||
                                (productData?.return_status === "Rejected" &&
                                  productData.status === 5)) &&
                                productData.userId !==
                                this.state.loginUserId && (
                                  <select
                                    className="custom-select form-control select_drop"
                                    value={productData?.status}
                                    onChange={(e) =>
                                      orderStatusHandler(
                                        productData._id,
                                        e.target.value
                                      )
                                    }
                                  >
                                    <option value="">Select Option</option>
                                    {productData.status === 1 &&
                                      this.checkEndTimeToCancel(
                                        productData
                                      ) && (
                                        <option value="7">Cancel Order</option>
                                      )}
                                    <option value="4"> Completed </option>
                                  </select>
                                )}
                              {[5].includes(productData.status) &&
                                productData?.return_status == "Accepted" &&
                                productData.userId !== this.state.loginUserId &&
                                productData.is_refunded == 0 && (
                                  <select
                                    className="custom-select form-control select_drop"
                                    value={productData?.status}
                                    onChange={(e) =>
                                      returnShipped(
                                        productData._id,
                                        e.target.value
                                      )
                                    }
                                  >
                                    <option value="">Select Option</option>
                                    <option value="1">Shipped</option>
                                  </select>
                                )}
                              {[10].includes(productData.status) &&
                                productData.userId === this.state.loginUserId &&
                                productData.is_refunded == 0 && (
                                  <select
                                    className="custom-select form-control select_drop"
                                    value={productData?.status}
                                    onChange={(e) =>
                                      orderStatusHandler(
                                        productData._id,
                                        e.target.value
                                      )
                                    }
                                  >
                                    <option value="">Select Option</option>
                                    <option value="11">Order Returned</option>
                                  </select>
                                )}
                              {[2, 3].includes(productData.status) &&
                                productData.userId !==
                                this.state.loginUserId && (
                                  <select
                                    className="custom-select form-control select_drop"
                                    value={productData?.status}
                                    onChange={(e) =>
                                      orderStatusHandler(
                                        productData._id,
                                        e.target.value
                                      )
                                    }
                                  >
                                    <option value="">Select Option</option>
                                    {productData.status === 1 && (
                                      <option value="7">Cancel Order</option>
                                    )}
                                    {productData.status === 2 && (
                                      <option value="3">Delivered </option>
                                    )}
                                    {(productData.status === 2 ||
                                      productData.status === 3 ||
                                      productData.products.delivery === 1) && (
                                        <option value="4"> Completed </option>
                                      )}
                                    {productData.status > 2 &&
                                      productData.status < 5 && (
                                        <option value="5"> Return </option>
                                      )}
                                    {/* {(productData.status === 5 && productData?.return_status === 'Rejected') && (<option value="6">Dispute Order{" "}</option>) }
                                          {(productData.status === 5 && productData?.return_status === 'Rejected') && (<option value="4">Complete Order{" "}</option>) }
                                          {(productData.status === 6 && productData?.return_status === 'Rejected') && (<option value="9">Resolve for Seller{" "}</option>) } */}
                                  </select>
                                )}
                              {[5, 6].includes(productData.status) &&
                                productData?.return_status === "Rejected" &&
                                productData.userId !==
                                this.state.loginUserId && (
                                  <select
                                    className="custom-select form-control select_drop"
                                    value={productData?.status}
                                    onChange={(e) =>
                                      orderStatusHandler(
                                        productData._id,
                                        e.target.value
                                      )
                                    }
                                  >
                                    <option value="">Select Option</option>
                                    {productData.status === 5 &&
                                      productData?.return_status ===
                                      "Rejected" && (
                                        <option value="6">
                                          Dispute Order{" "}
                                        </option>
                                      )}
                                    {productData.status === 5 &&
                                      productData?.return_status ===
                                      "Rejected" && (
                                        <option value="4">
                                          Complete Order{" "}
                                        </option>
                                      )}
                                    {productData.status === 6 &&
                                      productData?.return_status ===
                                      "Rejected" && (
                                        <option value="9">
                                          Resolve for Seller{" "}
                                        </option>
                                      )}
                                  </select>
                                )}
                            </div>

                            {productData.userId === this.state.loginUserId &&
                              productData.sellerRequest === 0 &&
                              productData.status === 4 ? (
                              <button
                                className="btn btn-sm btn-primary"
                                onClick={() =>
                                  sellerRequestHandler(productData._id, 1)
                                }
                              >
                                Request Money
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-lg-12 listside_newperent mobile_view"
          key={`upcomingLive_${productData._id}`}
        >
          <div className="product_listing_product_mobile">
            <div className="product-listing-product product_listing_product_block ">
              <div className="product-img-bx">
                {productData?.products && (
                  <Link
                    to={MyProfile.isLogin() ? {
                      pathname: "/livestreamingProduct/" +
                        productData.streamId,
                      query: {
                        productId: productData.products.productSlug,
                      },
                    } : "/upcomingLiveStreamingProduct/" +
                    productData.products.productSlug +
                    "/" +
                    productData.streamId
                    }
                  >
                    {productData.products.adType &&
                      productData.products.adType === "Featured" && (
                        <div className="specialtags">
                          <span
                            className={
                              (productData.products.adType === "Featured"
                                ? "black"
                                : "green") + "_special"
                            }
                          >
                            {productData.products.adType}{" "}
                          </span>
                        </div>
                      )}
                    {productData.products.productMainImage ? (
                      <img
                        src={General.showImage(
                          "products/resize-180-180",
                          productData.products.productMainImage
                        )}
                        onError={(e) =>
                          (e.target.src = "/images/no-image.jpg")
                        }
                        alt={productData.products.productName}
                      />
                    ) : (
                      <img src="/images/no-image.jpg" alt="product" />
                    )}

                    <div className="totalimg_sectab">
                      {productData.products.productImages
                        ? productData.products.productImages.length
                        : 0}{" "}
                      <i className="fas fa-camera"></i>
                    </div>
                  </Link>
                )}
              </div>
              <div className="product-outer-bx productBig-outer-bx">
                <div className="product-name-bx content_gobottom_new">
                  <div className="main_div_crd">
                    <div className="main_div_left">
                      {productData?.products && (
                        <Link
                          to={MyProfile.isLogin() ? {
                            pathname: "/livestreamingProduct/" +
                              productData.streamId,
                            query: {
                              productId: productData.products.productSlug,
                            },
                          } : "/upcomingLiveStreamingProduct/" +
                          productData.products.productSlug +
                          "/" +
                          productData.streamId
                          }
                        >
                          <h4 className="product_mobile_view">{productData?.products.productName}</h4>
                        </Link>
                      )}
                      <h5>
                        Duration: {productData.live_stream?.length} mins
                      </h5>
                      <h6>
                        <span>
                        </span>
                      </h6>
                      {productData?.products && (
                        <ul className="listofsubtabbx">
                          <Modal
                            isOpen={isModalOpen}
                            onRequestClose={this.handleCloseModal}
                            contentLabel="Buyer Protection"
                            className="modal-content"
                            overlayClassName="modal-overlay"
                          >
                            <button onClick={this.handleCloseModal} className="shipCloseBtn">
                              <i className="fa fa-times "></i>
                            </button>
                            <div className="product-outer-bx productBig-outer-bx">
                              <div className="product-name-bx content_gobottom_new prdNewRow_row">
                                <div className="viewAddressPopup viewAddressPopupTrack">
                                  {productData.products.delivery === 2 ? (
                                    <h5>
                                      <span>Shipping Address : </span>
                                      {`${productData?.registerbids?.deliveryAddress
                                        ?.name
                                        } ${productData?.registerbids?.deliveryAddress
                                          ?.address?.line1
                                        } ${productData?.registerbids?.deliveryAddress
                                          ?.address?.line2
                                        } ${productData?.registerbids?.deliveryAddress
                                          ?.address?.city
                                        } ${productData?.registerbids?.deliveryAddress
                                          ?.address?.country == "GB"
                                          ? "United Kingdom"
                                          : productData?.registerbids?.deliveryAddress
                                            ?.address?.country
                                        } ${productData?.registerbids?.deliveryAddress
                                          ?.address?.postal_code
                                        }`}
                                    </h5>
                                  ) :
                                    <span>No Address Found</span>
                                  }
                                </div>
                              </div>
                            </div>
                          </Modal>

                          {productData.user._id == userId ? (
                            <>
                              <li>
                                Bought Out By :{" "}
                                {productData?.winner?.users.firstName +
                                  " " +
                                  productData?.winner?.users.lastName}
                              </li>
                              <li>
                                Winning Price : {productData?.winner?.amount}
                              </li>
                            </>
                          ) : (
                            <>
                              <li>
                                Seller :{" "}
                                {productData?.user.firstName +
                                  " " +
                                  productData?.user.lastName}
                              </li>
                            </>
                          )}
                          {productData?.expected_delivery_date &&
                            productData.status === 2 && (
                              <li>
                                <strong>Expected Delivery</strong>
                                <span>
                                  <Moment format="YYYY/MM/DD">
                                    {productData?.expected_delivery_date}
                                  </Moment>
                                </span>
                              </li>
                            )}
                        </ul>
                      )}

                      {productData.status === 5 && (
                        <ul>
                          <li>
                            <strong>Return Reson :</strong>
                            {productData?.reson_id !== null ? (
                              <span>{productData?.reson_id["name"]}</span>
                            ) : (
                              <span>{productData?.other_reson}</span>
                            )}
                          </li>
                          {productData?.return_address &&
                            productData.return_address !== null && (
                              <li>
                                <strong>Return Address : </strong>
                                <span>{productData.return_address}</span>
                              </li>
                            )}
                          <li>
                            <div className="mt-2">
                              {productData?.return_status &&
                                productData?.return_status != null && (
                                  <>
                                    <span
                                      className={`labltable lablesct ${productData.return_status}`}
                                    >
                                      {productData.return_status}
                                    </span>
                                  </>
                                )}

                              {productData.userId ===
                                this.state.loginUserId &&
                                productData?.return_status === null && (
                                  <>
                                    <button
                                      className="btn-sm accept-theme mr-1"
                                      onClick={() =>
                                        this.setState({
                                          showAcceptModal: true,
                                        })
                                      }
                                    >
                                      Accept
                                    </button>
                                    <button
                                      className="btn-sm reject-theme"
                                      onClick={() =>
                                        this.returnOrderStatusHandler(
                                          "Rejected",
                                          productData?._id
                                        )
                                      }
                                    >
                                      Reject
                                    </button>
                                  </>
                                )}
                            </div>
                          </li>
                        </ul>
                      )}
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="mb-2">
              Auction Ended :{" "}
              {this.dateTimeConverson(productData.endTime)}
            </div>

            <div className="main_div_right">
              <div className="subtab_edit_ mt-2_ coustom_right_block coustom_right_mobile ">
                <span
                  className="labltable lablesct view_btn top_position "
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    this.setState({
                      modalProducts: productData?.bidders ?? [],
                      bidEnded: productData.endTime,
                      showModal: true,
                    })
                  }
                >
                  <i className="far fa-eye"></i> View
                </span>
                <span
                  className="labltable lablesct view_btn"
                  style={{ cursor: "pointer", marginBottom: "5px" }}
                  onClick={this.handleOpenModal}
                >
                  <i className="far fa-eye"></i> View Address
                </span>
                <div className="subtab_editlabl btn_div">

                  <div className="subtab_editlabl">
                    <span className="labltable lablesct">
                      {(() => {
                        if (productData.status === 1) {
                          return "Order Posted";
                        } else if (productData.status === 2) {
                          return "Shipped";
                        } else if (productData.status === 3) {
                          return "Delivered";
                        } else if (productData.status === 4) {
                          return "Completed";
                        } else if (
                          [11, 8, 9].includes(productData.status) ||
                          (productData.status === 5 &&
                            productData.is_refunded === 1)
                        ) {
                          return "Suspend";
                        } else if (productData.status === 6) {
                          return "Order Disputed";
                        } else if (productData.status === 7) {
                          return "Cancelled";
                        } else if (
                          [5].includes(productData.status) &&
                          productData?.return_status == "Accepted" &&
                          productData.userId !==
                          this.state.loginUserId &&
                          productData.is_refunded == 0
                        ) {
                          return "Return Order";
                        } else if (productData.status === 10) {
                          return "Return Order Shipped";
                        } else if (
                          [5].includes(productData.status) &&
                          productData?.return_status == null &&
                          productData.is_refunded == 0
                        ) {
                          return "Return Requested";
                        } else if (
                          [5].includes(productData.status) &&
                          productData?.return_status == "Accepted" &&
                          productData.userId ===
                          this.state.loginUserId &&
                          productData.is_refunded == 0
                        ) {
                          return "Return Accepted";
                        } else if (
                          [5].includes(productData.status) &&
                          productData?.return_status == "Rejected"
                        ) {
                          return "Return Rejected";
                        }
                      })()}
                    </span>
                  </div>
                </div>
                <div className="subtab_edit newPrdList_col-2 blankspace">
                  {(() => {
                    if (
                      productData.userId === this.state.loginUserId
                    ) {
                      if (productData.sellerRequest === 1) {
                        return (
                          <>
                            <div className="subtab_editlabl">
                              <span className="labltable lablesct mb-2">
                                Request Money
                              </span>
                            </div>
                          </>
                        );
                      } else if (productData.sellerRequest === 2)
                        return (
                          <>
                            <div className="subtab_editlabl">
                              <span className="labltable lablesct mb-2">
                                Release Money
                              </span>
                            </div>
                          </>
                        );
                    }
                  })()}

                  <div className="subtab_editlabl select_bx">
                    {productData.status === 1 &&
                      productData.userId ===
                      this.state.loginUserId && (
                        <select
                          className="custom-select form-control select_drop"
                          value={productData?.status}
                          onChange={(e) =>
                            orderStatusHandler(
                              productData._id,
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Option</option>
                          {productData.status === 1 && (
                            <option value="7">Cancel Order</option>
                          )}
                          {productData.status === 1 &&
                            productData.products.delivery === 2 && (
                              <option value="2">Shipped </option>
                            )}
                        </select>
                      )}
                    {productData.status === 6 &&
                      productData?.return_status === "Rejected" &&
                      productData.userId ===
                      this.state.loginUserId && (
                        <select
                          className="custom-select form-control select_drop"
                          value={productData?.status}
                          onChange={(e) =>
                            orderStatusHandler(
                              productData._id,
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Option</option>
                          <option value="8">Resolve for Buyer</option>
                        </select>
                      )}
                    {([1].includes(productData.status) ||
                      (productData?.return_status === "Rejected" &&
                        productData.status === 5)) &&
                      productData.userId !==
                      this.state.loginUserId && (
                        <select
                          className="custom-select form-control select_drop"
                          value={productData?.status}
                          onChange={(e) =>
                            orderStatusHandler(
                              productData._id,
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Option</option>
                          {productData.status === 1 &&
                            this.checkEndTimeToCancel(
                              productData
                            ) && (
                              <option value="7">Cancel Order</option>
                            )}
                          <option value="4"> Completed </option>
                        </select>
                      )}
                    {[5].includes(productData.status) &&
                      productData?.return_status == "Accepted" &&
                      productData.userId !== this.state.loginUserId &&
                      productData.is_refunded == 0 && (
                        <select
                          className="custom-select form-control select_drop"
                          value={productData?.status}
                          onChange={(e) =>
                            returnShipped(
                              productData._id,
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Option</option>
                          <option value="1">Shipped</option>
                        </select>
                      )}
                    {[10].includes(productData.status) &&
                      productData.userId === this.state.loginUserId &&
                      productData.is_refunded == 0 && (
                        <select
                          className="custom-select form-control select_drop"
                          value={productData?.status}
                          onChange={(e) =>
                            orderStatusHandler(
                              productData._id,
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Option</option>
                          <option value="11">Order Returned</option>
                        </select>
                      )}
                    {[2, 3].includes(productData.status) &&
                      productData.userId !==
                      this.state.loginUserId && (
                        <select
                          className="custom-select form-control select_drop"
                          value={productData?.status}
                          onChange={(e) =>
                            orderStatusHandler(
                              productData._id,
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Option</option>
                          {productData.status === 1 && (
                            <option value="7">Cancel Order</option>
                          )}
                          {productData.status === 2 && (
                            <option value="3">Delivered </option>
                          )}
                          {(productData.status === 2 ||
                            productData.status === 3 ||
                            productData.products.delivery === 1) && (
                              <option value="4"> Completed </option>
                            )}
                          {productData.status > 2 &&
                            productData.status < 5 && (
                              <option value="5"> Return </option>
                            )}
                          {/* {(productData.status === 5 && productData?.return_status === 'Rejected') && (<option value="6">Dispute Order{" "}</option>) }
                                              {(productData.status === 5 && productData?.return_status === 'Rejected') && (<option value="4">Complete Order{" "}</option>) }
                                              {(productData.status === 6 && productData?.return_status === 'Rejected') && (<option value="9">Resolve for Seller{" "}</option>) } */}
                        </select>
                      )}
                    {[5, 6].includes(productData.status) &&
                      productData?.return_status === "Rejected" &&
                      productData.userId !==
                      this.state.loginUserId && (
                        <select
                          className="custom-select form-control select_drop"
                          value={productData?.status}
                          onChange={(e) =>
                            orderStatusHandler(
                              productData._id,
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Option</option>
                          {productData.status === 5 &&
                            productData?.return_status ===
                            "Rejected" && (
                              <option value="6">
                                Dispute Order{" "}
                              </option>
                            )}
                          {productData.status === 5 &&
                            productData?.return_status ===
                            "Rejected" && (
                              <option value="4">
                                Complete Order{" "}
                              </option>
                            )}
                          {productData.status === 6 &&
                            productData?.return_status ===
                            "Rejected" && (
                              <option value="9">
                                Resolve for Seller{" "}
                              </option>
                            )}
                        </select>
                      )}
                  </div>

                  {productData.userId === this.state.loginUserId &&
                    productData.sellerRequest === 0 &&
                    productData.status === 4 ? (
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() =>
                        sellerRequestHandler(productData._id, 1)
                      }
                    >
                      Request Money
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={showModal}
          ariaHideApp={false}
          className="popupModal stripePaymentModal"
          onRequestClose={() =>
            this.setState({ showModal: false, modalProducts: [] })
          }
          style={{
            content: {
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "60%",
            },
          }}
        >
          <button
            onClick={() =>
              this.setState({ showModal: false, modalProducts: [] })
            }
            className="shipCloseBtn"
          >
            <i className="fa fa-times"></i>
          </button>
          <div className="scrollblePopUp">
            <div className="col-lg-12 listside_newperent">
              <p className="font-weight-bold">
                <span>Bidders : {modalProducts.length}</span>
                <span className="ml-5">
                  Bid Time Ended : {this.dateTimeConverson(this.state.bidEnded)}
                </span>
              </p>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Bidders</th>
                      <th>Bid Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {modalProducts &&
                      modalProducts.map((bidder, index) => (
                        <tr key={`bidder_${bidder._id}`}>
                          <td>
                            <div>
                              {bidder?.users?.profilePicture ? (
                                <img
                                  src={General.showImageNew(
                                    "profile",
                                    bidder?.users?.profilePicture
                                  )}
                                  onError={(e) =>
                                    (e.target.src = "/images/user.png")
                                  }
                                  alt={
                                    bidder?.users?.firstName +
                                    " " +
                                    bidder?.users?.lastName
                                  }
                                  width="30"
                                  className="mCS_img_loaded"
                                />
                              ) : (
                                <img
                                  src="/images/user.png"
                                  alt={
                                    bidder?.users?.firstName +
                                    " " +
                                    bidder?.users?.lastName
                                  }
                                  width="30"
                                  className="mCS_img_loaded"
                                />
                              )}
                              <p>
                                <small>
                                  {bidder?.users?.firstName +
                                    " " +
                                    bidder?.users?.lastName}
                                </small>
                              </p>
                            </div>
                          </td>
                          <td>
                            {index === 0 ? (
                              <b>
                                You won the bid{" "}
                                {General.showPriceDecimal(bidder?.amount)}{" "}
                              </b>
                            ) : (
                              <b>
                                You were out bid{" "}
                                {General.showPriceDecimal(bidder?.amount)}{" "}
                              </b>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.returnShippedModal}
          style={customStyles}
          contentLabel="shippedModal Modal"
          ariaHideApp={false}
          className="shipModal"
          onRequestClose={() =>
            this.setState({
              returnShippedModal: false,
              shippingNote: "",
            })
          }
        >
          <button onClick={() => this.closeModal()} className="shipCloseBtn">
            <i className="fa fa-times"></i>
          </button>
          <form className="form-horizontal" onSubmit={this.returnsubmitHandler}>
            <div className="form-group row">
              <label className="control-label col-sm-12" for="expdate">
                Expected Delivery Date:
              </label>
              <div className="col-sm-12">
                <DatePicker
                  id="expdate"
                  className="form-control"
                  onChange={this.selectReturnExpDeliveryData}
                  value={this.state.returnExpectedDeliveryDate}
                />
                <p
                  id="expected-date"
                  className="text-danger"
                  style={{ display: "none" }}
                >
                  Expected Delivery Date is required*
                </p>
              </div>
            </div>
            <div className="form-group row">
              <label className="control-label col-sm-12">Shipping Note:</label>
              <div className="col-sm-12">
                <textarea
                  name="returnShippingNote"
                  value={this.state.returnShippingNote}
                  onChange={this.handleReturnShippingNote}
                  class="form-control"
                  rows="2"
                  placeholder="shipping note.."
                ></textarea>
                <p
                  id="shipping-note"
                  className="text-danger"
                  style={{ display: "none" }}
                >
                  Shipping Note is required*
                </p>
              </div>
            </div>
            <div class="form-group">
              <div class="col-sm-offset-2 col-sm-12 text-center">
                <button type="submit" className="btn themeBtn">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </Modal>
        <Modal
          isOpen={this.state.modalIsOpen}
          style={customStyles}
          contentLabel="shippedModal Modal"
          ariaHideApp={false}
          className="shipModal"
          onRequestClose={() =>
            this.setState({
              modalIsOpen: false,
              shippingNote: "",
            })
          }
        >
          <button onClick={() => this.closeModal()} className="shipCloseBtn">
            <i className="fa fa-times"></i>
          </button>
          <form className="form-horizontal" onSubmit={this.submitHandler}>
            <div className="form-group row">
              <label className="control-label col-sm-12" htmlFor="expdate">
                Expected Delivery Date:
              </label>
              <div className="col-sm-12">
                <DatePicker
                  id="expdate"
                  className="form-control"
                  onChange={this.selectExpDeliveryData}
                  value={this.state.expectedDeliveryDate}
                />
                <p
                  id="expected-date"
                  className="text-danger"
                  style={{ display: "none" }}
                >
                  Expected Delivery Date is required*
                </p>
              </div>
            </div>
            <div className="form-group row">
              <label className="control-label col-sm-12">Shipping Note:</label>
              <div className="col-sm-12">
                <textarea
                  name="shippingNote"
                  value={this.state.shippingNote}
                  onChange={this.handleShippingNote}
                  className="form-control"
                  rows="2"
                  placeholder="shipping note.."
                ></textarea>
                <p
                  id="shipping-note"
                  className="text-danger"
                  style={{ display: "none" }}
                >
                  Shipping Note is required*
                </p>
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-offset-2 col-sm-12 text-center">
                <button type="submit" className="btn themeBtn">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </Modal>
        <Modal
          isOpen={this.state.showAddressModal}
          ariaHideApp={false}
          onRequestClose={() =>
            this.setState({
              showAddressModal: false,
              address: "",
            })
          }
          className="shipModal"
          style={customStyles}
        >
          <button
            onClick={() =>
              this.setState({
                showAddressModal: false,
                address: "",
              })
            }
            className="shipCloseBtn"
          >
            <i className="fa fa-times"></i>
          </button>

          <form className="form-horizontal" onSubmit={this.handleFormSubmit}>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <textarea
                    name="address"
                    value={this.state.address}
                    onChange={(e) => this.setState({ address: e.target.value })}
                    className="form-control"
                    rows="4"
                    placeholder="Return address.."
                  ></textarea>
                  <p
                    id="return-address"
                    className="text-danger"
                    style={{ display: "none" }}
                  >
                    Return Address is required*
                  </p>
                </div>
              </div>

              <div className="col-sm-12 text-center">
                <div className="form-group">
                  <button type="submit" className="btn themeBtn">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Modal>
        <Modal
          isOpen={this.state.showAcceptModal}
          onRequestClose={() =>
            this.setState({
              showAcceptModal: false,
            })
          }
          className="shipModal"
          style={customStyles}
        >
          <button
            onClick={() => this.setState({ showAcceptModal: false })}
            className="shipCloseBtn"
          >
            <i className="fa fa-times"></i>
          </button>

          <div className="income_heading py-2">
            <h3>Would you like the item to be returned?</h3>
          </div>
          <div className="btn-block text-center">
            <button
              className="btn btn-success mr-2"
              onClick={() =>
                this.setState({
                  showAcceptModal: false,
                  showAddressModal: true,
                })
              }
            >
              Yes
            </button>
            <button
              className="btn btn-danger"
              onClick={() => this.returnAddressHandler(0)}
            >
              No
            </button>
          </div>
        </Modal>
        {this.state.returnModalIsOpen && (
          <LiveReturnModalComponent
            returnModalIsOpen={this.state.returnModalIsOpen}
            id={productData._id}
            handler={this.handleChildReq}
            closeModal={this.handleChildCloseReq}
            key={`return_model_${productData._id}`}
          />
        )}
      </div>
    );
  }
}

export default BoughtLiveAuction;
