import General from "Helpers/General";
import React, { Component } from "react";
import Moment from "react-moment";
import StarRatings from "react-star-ratings";

export class ReviewComponent extends Component {
  render() {
    const { _id, review, rating, userId, replies } = this.props.review;
    return (
      <div class="review_block">
        <div class="row align-items-center">
          <div class="col-lg-12">
            <div class="detail-owner-name-bx clearfix review_author">
              <div class="detail-owner-img">
                {userId && userId.profilePicture ? (
                  <img
                    src={General.showImageNew("profile", userId.profilePicture)}
                    onError={
                      (e) => e.target.src = '/images/no-image.jpg' 
                    }
                    alt={userId.profilePicture}
                  />
                ) : (
                  <img src="/images/no-image.jpg" alt="image" />
                )}
              </div>
              <div class="detail-owner-name">
                <h3>
                  {userId && userId.firstName} {""} {userId && userId.lastName}
                </h3>
                <div class="rated-customer-bx-inner">
                  <div class="rated-star ">
                    <span>Rated</span>
                    <StarRatings
                      rating={rating}
                      starRatedColor="#249ee4"
                      starDimension="17px"
                      starSpacing="1px"
                      numberOfStars={5}
                    />
                  </div>
                  <h2 className="font-size-14">{review}</h2>
                </div>
              </div>
            </div>
          </div>
          {replies &&
            replies.map((reply) => (
              <div class="review_reply" key={reply._id}>
                <div class="row align-items-center w-100">
                  <div class="col-auto">
                    <div class="detail-owner-name-bx clearfix review_author">
                      <div class="detail-owner-img reply_img_b">
                        {reply.userId && reply.userId.profilePicture ? (
                          <img
                            src={General.showImageNew(
                              "profile",
                              reply.userId.profilePicture
                            )}
                            onError={
                              (e) => e.target.src = '/images/user.png' 
                            }
                            alt={reply.userId.profilePicture}
                          />
                        ) : (
                          <img src="/images/user.png" alt="image1" />
                        )}
                        {/* <span class="live-dot"></span> */}
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="rated-customer-bx-inner review_author">
                      <h3>
                        {" "}
                        {reply.userId?.firstName} {""} {reply.userId?.lastName}
                      </h3>
                      <h2>{reply.reply}</h2>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

export default ReviewComponent;
