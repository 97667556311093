import General from "Helpers/General";
import React, { Component } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import Modal from "react-modal";

export class AchievementLiveAuction extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.state = {
      showModal: false,
      modalProducts: [],
      bidEnded: "",
      isPaid: 0,
      showWinner: true,
    };
  }

  dateTimeConverson = (dateObj) => {
    const dateTime = new Date(dateObj);
    return dateTime.toLocaleString("en-ZA", { hour12: true });
  };

  render() {
    const { productData, loginUser } = this.props;
    const { showModal, modalProducts } = this.state;

    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      },
    };

    return (
      <>
        <div
          className="col-lg-12 listside_newperent"
          key={`upcomingLive_${productData._id}`}
        >

          <div className="background-color mb-2">
            <div className="product-listing-product desktop_view">
              <div className="row">
                <div className="col-md-3">
                  <div className="product-img-bx">
                    <Link to={`/product/${productData.products.productSlug}`}>
                      {productData.products.adType &&
                        productData.products.adType === "Featured" && (
                          <div className="specialtags">
                            <span
                              className={
                                (productData.products.adType === "Featured"
                                  ? "black"
                                  : "green") + "_special"
                              }
                            >
                              {productData.products.adType}{" "}
                            </span>
                          </div>
                        )}
                      {productData.products.productMainImage ? (
                        <img
                          src={General.showImage(
                            "products/resize-180-180",
                            productData.products.productMainImage
                          )}
                          onError={(e) => (e.target.src = "/images/no-image.jpg")}
                          alt={productData.products.productName}
                        />
                      ) : (
                        <img src="/images/no-image.jpg" alt="product" />
                      )}

                      <div className="totalimg_sectab">
                        {productData.products.productImages
                          ? productData.products.productImages.length
                          : 0}{" "}
                        <i className="fas fa-camera"></i>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="product-outer-bx productBig-outer-bx">
                    <div className="product-name-bx content_gobottom_new">
                      <div className="main_div_crd">
                        <div className="main_div_left">
                          {/* {productData?.createdAt && (
                            <Moment fromNow>{productData?.createdAt}</Moment>
                          )} */}

                          <Link
                            to={`/product/${productData.products.productSlug}`}
                          >
                            <h4>{productData.products.productName}</h4>
                          </Link>

                          {/* <h5>Duration: { (productData.endTime && productData.startTime) ? ((Date.parse(productData.endTime) - Date.parse(productData.startTime))/60000) : 0 } mins</h5> */}
                          <h5>
                            Duration: {productData.live_stream?.length} mins
                          </h5>

                          <h6>
                            <span></span>
                          </h6>

                          <ul className="listofsubtabbx">
                            <li>
                              Auction Ended :{" "}
                              {productData.endTime
                                ? this.dateTimeConverson(productData.endTime)
                                : "Seller was not available"}
                            </li>
                            {productData.isPaid !== 0 &&
                              productData.status === 4 && (
                                <>
                                  {productData.user._id == loginUser ? (
                                    <>
                                      <li>
                                        Bought Out By :{" "}
                                        {productData?.winner?.users.firstName +
                                          " " +
                                          productData?.winner?.users.lastName}
                                      </li>
                                      <li>
                                        Winning Price :{" "}
                                        {General.showPriceDecimal(
                                          productData?.winner?.amount
                                        )}
                                      </li>
                                    </>
                                  ) : (
                                    <>
                                      <li>
                                        Seller :{" "}
                                        {productData?.user.firstName +
                                          " " +
                                          productData?.user.lastName}
                                      </li>
                                    </>
                                  )}
                                </>
                              )}
                            <li>
                              <strong>Achievement: </strong>
                              <span>
                                <Moment format="YYYY/MM/DD">
                                  {productData?.orderedTime}
                                </Moment>
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="main_div_right">
                          <div className="subtab_edit_ mt-2_ coustom_right_block">
                            <div className="subtab_editlabl btn_div">
                              {productData?.bidders &&
                                productData.bidders.length > 0 && (
                                  <span
                                    className="labltable lablesct view_btn"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.setState({
                                        modalProducts: productData?.bidders ?? [],
                                        bidEnded: productData.endTime,
                                        isPaid: productData.isPaid,
                                        showModal: true,
                                      })
                                    }
                                  >
                                    <i className="far fa-eye"></i> View
                                  </span>
                                )}
                              <div className="subtab_editlabl">
                                <span className="labltable lablesct">
                                  {(() => {
                                    if (
                                      productData.status === 4 &&
                                      productData?.isPaid === 1
                                    ) {
                                      return "Completed";
                                    } else {
                                      return "Inactive";
                                    }
                                  })()}
                                </span>
                              </div>
                            </div>
                            <div className="subtab_edit newPrdList_col-2 blankspace">
                              {(() => {
                                if (productData.userId === loginUser) {
                                  if (productData.sellerRequest === 1) {
                                    return (
                                      <>
                                        <div className="subtab_editlabl">
                                          <span className="labltable lablesct mb-2">
                                            Request Money
                                          </span>
                                        </div>
                                      </>
                                    );
                                  } else if (productData.sellerRequest === 2)
                                    return (
                                      <>
                                        <div className="subtab_editlabl">
                                          <span className="labltable lablesct mb-2">
                                            Release Money
                                          </span>
                                        </div>
                                      </>
                                    );
                                }
                              })()}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="product-listing-product mobile_view">

              <div className="product_listing_product_block">
                <div className="product-img-bx">
                  <Link to={`/product/${productData.products.productSlug}`}>
                    {productData.products.adType &&
                      productData.products.adType === "Featured" && (
                        <div className="specialtags">
                          <span
                            className={
                              (productData.products.adType === "Featured"
                                ? "black"
                                : "green") + "_special"
                            }
                          >
                            {productData.products.adType}{" "}
                          </span>
                        </div>
                      )}
                    {productData.products.productMainImage ? (
                      <img
                        src={General.showImage(
                          "products/resize-180-180",
                          productData.products.productMainImage
                        )}
                        onError={(e) => (e.target.src = "/images/no-image.jpg")}
                        alt={productData.products.productName}
                      />
                    ) : (
                      <img src="/images/no-image.jpg" alt="product" />
                    )}

                    <div className="totalimg_sectab">
                      {productData.products.productImages
                        ? productData.products.productImages.length
                        : 0}{" "}
                      <i className="fas fa-camera"></i>
                    </div>
                  </Link>
                </div>

                <div className="product-outer-bx productBig-outer-bx">
                  <div className="product-name-bx content_gobottom_new">
                    <div className="main_div_crd">
                      <div className="main_div_left">
                        {/* {productData?.createdAt && (
                            <Moment fromNow>{productData?.createdAt}</Moment>
                          )} */}
                        <Link
                          to={`/product/${productData.products.productSlug}`}
                        >
                          <h4 className="product_mobile_view">{productData.products.productName}</h4>
                        </Link>
                        {/* <h5>Duration: { (productData.endTime && productData.startTime) ? ((Date.parse(productData.endTime) - Date.parse(productData.startTime))/60000) : 0 } mins</h5> */}
                        <h5>
                          Duration: {productData.live_stream?.length} mins
                        </h5>

                        <h6>
                          <span></span>
                        </h6>

                        <ul className="listofsubtabbx">
                          <li>
                            Auction Ended :{" "}
                            {productData.endTime
                              ? this.dateTimeConverson(productData.endTime)
                              : "Seller was not available"}
                          </li>
                          {productData.isPaid !== 0 &&
                            productData.status === 4 && (
                              <>
                                {productData.user._id == loginUser ? (
                                  <>
                                    <li>
                                      Bought Out By :{" "}
                                      {productData?.winner?.users.firstName +
                                        " " +
                                        productData?.winner?.users.lastName}
                                    </li>
                                    <li>
                                      Winning Price :{" "}
                                      {General.showPriceDecimal(
                                        productData?.winner?.amount
                                      )}
                                    </li>
                                  </>
                                ) : (
                                  <>
                                    <li>
                                      Seller :{" "}
                                      {productData?.user.firstName +
                                        " " +
                                        productData?.user.lastName}
                                    </li>
                                  </>
                                )}
                              </>
                            )}
                          <li>
                            <strong>Achievement: </strong>
                            <span>
                              <Moment format="YYYY/MM/DD">
                                {productData?.orderedTime}
                              </Moment>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main_div_right">
                <div className="subtab_edit_ mt-2_ coustom_right_block">
                  <div className="subtab_editlabl btn_div">
                    {productData?.bidders &&
                      productData.bidders.length > 0 && (
                        <span
                          className="labltable lablesct view_btn"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            this.setState({
                              modalProducts: productData?.bidders ?? [],
                              bidEnded: productData.endTime,
                              isPaid: productData.isPaid,
                              showModal: true,
                            })
                          }
                        >
                          <i className="far fa-eye"></i> View
                        </span>
                      )}
                    <div className="subtab_editlabl">
                      <span className="labltable lablesct">
                        {(() => {
                          if (
                            productData.status === 4 &&
                            productData?.isPaid === 1
                          ) {
                            return "Completed";
                          } else {
                            return "Inactive";
                          }
                        })()}
                      </span>
                    </div>
                  </div>
                  <div className="subtab_edit newPrdList_col-2 blankspace">
                    {(() => {
                      if (productData.userId === loginUser) {
                        if (productData.sellerRequest === 1) {
                          return (
                            <>
                              <div className="subtab_editlabl">
                                <span className="labltable lablesct mb-2">
                                  Request Money
                                </span>
                              </div>
                            </>
                          );
                        } else if (productData.sellerRequest === 2)
                          return (
                            <>
                              <div className="subtab_editlabl">
                                <span className="labltable lablesct mb-2">
                                  Release Money
                                </span>
                              </div>
                            </>
                          );
                      }
                    })()}
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <Modal
          isOpen={showModal}
          ariaHideApp={false}
          className="popupModal stripePaymentModal"
          onRequestClose={() =>
            this.setState({ showModal: false, modalProducts: [] })
          }
          style={{
            content: {
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "60%",
            },
          }}
        >
          <button
            onClick={() =>
              this.setState({ showModal: false, modalProducts: [] })
            }
            className="shipCloseBtn"
          >
            <i className="fa fa-times"></i>
          </button>
          <div className="scrollblePopUp">
            <div className="col-lg-12 listside_newperent">
              <p className="font-weight-bold">
                <span>Bidders : {modalProducts.length}</span>
                <span className="ml-5">
                  Bid Time Ended :{" "}
                  {this.state.bidEnded
                    ? this.dateTimeConverson(this.state.bidEnded)
                    : "Seller was not available"}{" "}
                </span>
              </p>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Bidders</th>
                      <th>Bid Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {modalProducts &&
                      modalProducts.map((bidder, index) => (
                        <tr key={`bidder_${bidder._id}`}>
                          <td>
                            <div>
                              {bidder?.users?.profilePicture ? (
                                <img
                                  src={General.showImageNew(
                                    "profile",
                                    bidder?.users?.profilePicture
                                  )}
                                  onError={(e) =>
                                    (e.target.src = "/images/user.png")
                                  }
                                  alt={
                                    bidder?.users?.firstName +
                                    " " +
                                    bidder?.users?.lastName
                                  }
                                  width="30"
                                  className="mCS_img_loaded"
                                />
                              ) : (
                                <img
                                  src="/images/user.png"
                                  alt={
                                    bidder?.users?.firstName +
                                    " " +
                                    bidder?.users?.lastName
                                  }
                                  width="30"
                                  className="mCS_img_loaded"
                                />
                              )}
                              <p>
                                <small>
                                  {bidder?.users?.firstName +
                                    " " +
                                    bidder?.users?.lastName}
                                </small>
                              </p>
                            </div>
                          </td>
                          <td>
                            {this.state.isPaid === 1 ? (
                              index === 0 ? (
                                <b>
                                  You won the bid{" "}
                                  {General.showPriceDecimal(bidder?.amount)}
                                </b>
                              ) : (
                                <b>
                                  You were out bid{" "}
                                  {General.showPriceDecimal(bidder?.amount)}{" "}
                                </b>
                              )
                            ) : (
                              <b>(No charge)</b>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default AchievementLiveAuction;
